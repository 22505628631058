import React from "react";
import UserRequestList from "../component/UserRequestList";
import UserSearch from "../component/UserSearch";

//other admin functions of user management will be added here
const User = () => {
  return (
    <div className="user-page-wrapper">
      <UserRequestList />
      <UserSearch />
    </div>
  );
};

export default User;
