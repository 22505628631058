import { GET_TEAMS, GET_TEAMS_SUCCESS, GET_TEAMS_FAIL } from "../actions/team";
const initialState = {
  teams: [],
  teamsLoading: false,
};

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        teamsLoading: true,
      };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        // matches: [...state.matches, ...action.data],
        teams: action.data,
        teamsLoading: false,
      };
    case GET_TEAMS_FAIL:
      return {
        ...state,
        teamsLoading: false,
      };
    default:
      return state;
  }
}
