import React, { useState } from "react";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import {
  acceptConnectionRequest,
  rejectConnectionRequest,
} from "../store/actions/user";

const UserRequest = ({ request }) => {
  const serverDatetime = new Date(request.created_at);
  const localeDatetime = new Date(
    serverDatetime.getTime() - serverDatetime.getTimezoneOffset() * 60000
  ).toLocaleString();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(0);

  const handleClose = () => {
    setOpen(0);
  };

  const handleAccept = () => {
    setOpen(1);
  };

  const confirmAccept = () => {
    dispatch(acceptConnectionRequest(request.id));
    handleClose();
  };

  const handleReject = () => {
    setOpen(2);
  };

  const confirmReject = () => {
    dispatch(rejectConnectionRequest(request.id));
    handleClose();
  };

  return (
    <tr>
      <th>{localeDatetime}</th>
      <th>{request.username}</th>
      <th>{request.user_name}</th>
      <th>{request.player_name}</th>
      <th>
        <div className="button-wrapper">
          <Button variant="outlined" onClick={handleAccept}>
            Accept
          </Button>
          <Button variant="outlined" color="error" onClick={handleReject}>
            Reject
          </Button>
        </div>
      </th>

      {/* Dialogs */}
      <Dialog
        open={open === 1 ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Accept Request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to ACCEPT this request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={confirmAccept} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open === 2 ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reject Request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to REJECT this request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={confirmReject} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </tr>
  );
};

export default UserRequest;
