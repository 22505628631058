import React, { useState, useEffect } from "react";
import proam from "../images/starting-five/proam.png";
import division1 from "../images/starting-five/division-1.png";
import division2 from "../images/starting-five/division-2.png";
import division3 from "../images/starting-five/division-3.png";
import division4 from "../images/starting-five/division-4.png";
import division5 from "../images/starting-five/division-5.png";
import division6 from "../images/starting-five/division-6.png";
import hkkba from "../images/starting-five/hkkba.png";
import u16 from "../images/starting-five/u-16.png";
import u14 from "../images/starting-five/u-14.png";
import u12 from "../images/starting-five/u-12.png";
import Button from "@mui/material/Button";
import "./StartingFive.scss";

const StartingFive = ({ props }) => {
  const [image, setImage] = useState(null);
  const [fontColor, setFontColor] = useState("white");
  const [matchNameColor, setMatchNameColor] = useState("white");

  useEffect(() => {
    if (props.league) {
      switch (props.league) {
        case "Sketch Pro Am":
          setImage(proam);
          setFontColor("black");
          break;
        case "Joint Dynamics D1":
          setImage(division1);
          break;
        case "Masterwook D2":
          setImage(division2);
          setFontColor("black");
          setMatchNameColor("black");
          break;
        case "Refundz D3":
          setImage(division3);
          break;
        case "Zanchee D4":
          setImage(division4);
          break;
        case "Zerve D5":
          setImage(division5);
          break;
        case "Sketch D6":
          setImage(division6);
          break;
        case "HKKBL" || "WHKKBL":
          setImage(hkkba);
          break;
        case "U-16":
          setImage(u16);
          break;
        case "U-14 D1" || "U-14 D2":
          setImage(u14);
          break;
        case "U-12 D1" || "U-12 D2":
          setImage(u12);
          break;
        default:
          setImage(null);
      }
    }
  }, [props.league]);

  const exportStartingFive = () => {
    window.domtoimage
      .toBlob(document.querySelector(".starting-five"))
      .then(function (blob) {
        window.saveAs(blob, `${props.match}.png`);
      });
  };

  return (
    <div className="starting-five-wrapper">
      <div
        className="starting-five"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="starting-team-names">
          <span className="starting-home-team-name">
            {props.homeTeam?.name.toUpperCase()}
          </span>
          <span
            className="starting-away-team-name"
            style={{ color: fontColor }}
          >
            {props.awayTeam?.name.toUpperCase()}
          </span>
        </div>
        <div className="starting-five-players">
          <div className="home-starting-five">
            {props.homePlayers?.map((player) => {
              if (player.is_starter) {
                return (
                  <div
                    className={`starting-five-player ${player.position}`}
                    key={player.id}
                    style={{ color: fontColor }}
                  >
                    <span className="starting-five-player-position">
                      {player.position}
                    </span>
                    <div className="starting-five-player-name-wrapper">
                      <span className="starting-five-player-name">
                        {player.name.toUpperCase()}
                      </span>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="away-starting-five">
            {props.awayPlayers?.map((player) => {
              if (player.is_starter) {
                return (
                  <div
                    className={`starting-five-player ${player.position}`}
                    key={player.id}
                  >
                    <span className="starting-five-player-position">
                      {player.position}
                    </span>
                    <div className="starting-five-player-name-wrapper">
                      <span className="starting-five-player-name">
                        {player.name.toUpperCase()}
                      </span>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="starting-match-name-wrapper">
          <div
            className="starting-match-name"
            style={{ color: matchNameColor }}
          >
            {props.match}
          </div>
        </div>
      </div>
      <Button
        variant="outlined"
        sx={{ margin: "0.5rem" }}
        onClick={exportStartingFive}
      >
        Download
      </Button>
    </div>
  );
};

export default StartingFive;
