import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  excludePlayerFromSeason,
  updateBackNumber,
} from "../store/actions/player";
import { getSeasonRoster } from "../store/actions/teamDetail";
import DialogModal from "./DialogModal";

const SeasonRosterTable = ({ teamId, selectedSeasonId }) => {
  // to get and update season roster
  const dispatch = useDispatch();
  const { seasonRoster } = useSelector((store) => store.teamDetailReducer);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);

  // for delete dialog
  const [rowId, setRowId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const title = "Confirm Remove Season Player";
  const [content, setContent] = useState("");

  // update rows if a row of seasonRoster is updated
  useEffect(() => {
    if (seasonRoster?.length > 0) {
      setRows(seasonRoster);
    } else {
      setRows([]);
    }
  }, [seasonRoster]);

  // update back number -> get season roster again
  useEffect(() => {
    if (isUpdated) {
      dispatch(getSeasonRoster(teamId, selectedSeasonId));
      setIsUpdated(false);
    }
  }, [isUpdated]);

  const openDeleteDialog = () => {
    setOpenDelete(true);
  };

  const closeDeleteDialog = () => {
    setOpenDelete(false);
  };

  // prevent default when moving out of the row
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // switch mode
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id, name, teamName, seasonName) => () => {
    setRowId(id);
    setContent(`
      Do you confirm to remove ${name} from ${teamName} in ${seasonName}?
      All the stats of ${name} in ${seasonName} ${teamName} will be deleted if there is any.
    `);
    openDeleteDialog();
  };

  const confirmDelete = () => {
    dispatch(excludePlayerFromSeason(rowId)).then(() => {
      setIsUpdated(true);
    });
    closeDeleteDialog();
    setRowId("");
    setContent("");
  };

  // reset row modification when canceled
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  // request update back number -> get season roster again
  const processRowUpdate = (editedRow) => {
    dispatch(updateBackNumber(editedRow.id, editedRow.back_number)).then(() => {
      setIsUpdated(true);
    });
    return editedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "player_name", headerName: "Name", editable: false, flex: 1 },
    {
      field: "back_number",
      headerName: "Back Number",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: (props) => {
        const id = props.id;
        const name = props.row.player_name;
        const teamName = props.row.team_name;
        const seasonName = props.row.season_name;

        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={"save" + id}
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={"cancel" + id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={"edit" + id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            key={"delete" + id}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, name, teamName, seasonName)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
      <DialogModal
        open={openDelete}
        onClose={closeDeleteDialog}
        onCancel={closeDeleteDialog}
        onSubmit={confirmDelete}
        title={title}
        content={content}
      />
    </Box>
  );
};

export default SeasonRosterTable;
