import React from "react";
import "./MatchDetailStat.scss";
import { useSelector } from "react-redux";
import MatchStatUpdateRow from "../component/MatchStatUpdateRow";

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>NAME</th>
        <th>MIN</th>
        <th>PTS</th>
        <th>AST</th>
        <th>DREB</th>
        <th>OREB</th>
        <th>REB</th>
        <th>FGA</th>
        <th>FGM</th>
        <th>3PA</th>
        <th>3PM</th>
        <th>FTA</th>
        <th>FTM</th>
        <th>STL</th>
        <th>BLK</th>
        <th>PF</th>
        <th>TO</th>
      </tr>
    </thead>
  );
};

const MatchDetailStat = () => {
  const { matchDetail } = useSelector((store) => store.matchDetailReducer);
  return (
    <div className="match-detail-stat-cotainer">
      <h2>{matchDetail?.home_team.name}</h2>
      <table className="match-detail-stat-table">
        <TableHeader />
        <tbody>
          {matchDetail?.home_team?.match_stats
            ?.slice(0, -1)
            .map((stat, index) => {
              return (
                <MatchStatUpdateRow
                  key={index}
                  stat={stat}
                  match_id={matchDetail.match_id}
                />
              );
            })}
        </tbody>
      </table>
      <h2>{matchDetail?.away_team.name}</h2>
      <table className="match-detail-stat-table">
        <TableHeader />
        <tbody>
          {matchDetail?.away_team?.match_stats
            ?.slice(0, -1)
            .map((stat, index) => {
              return (
                <MatchStatUpdateRow
                  key={index}
                  stat={stat}
                  match_id={matchDetail.match_id}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default MatchDetailStat;
