import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MatchDetail.scss";
import { useParams } from "react-router";
import {
  getMatchDetail,
  forfeit,
  deleteMatch,
  uploadStats,
  updateMatchLocation,
  updateMatchName,
  sendEmailNotification,
  updateMatchDeadline,
  uploadStats_V2,
  getMatchImages,
  syncMatchWinner,
} from "../store/actions/matchDetail";
import { Link } from "react-router-dom";

import { Route, Routes } from "react-router-dom";
import MatchDetailContent from "./MatchDetailContent";
import MatchDetailStat from "./MatchDetailStat";
import MatchDetailPlayers from "./MatchDetailPlayers";
import MatchDetailPhotos from "./MatchDetailPhotos";
import {
  Backdrop,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const MatchDetail = () => {
  const { matchId } = useParams();
  const [ffType, setFfType] = useState("");
  const [statsFileName, setStatsFileName] = useState("");
  const [statsFile, setStatsFile] = useState("");
  const [statsFileNameV2, setStatsFileNameV2] = useState("");
  const [statsFileV2, setStatsFileV2] = useState("");
  const [matchName, setMatchName] = useState("");
  const [matchNameDisabled, setMatchNameDisabled] = useState(true);
  const [matchLocation, setMatchLocation] = useState("");
  const [matchLocationDisabled, setMatchLocationDisabled] = useState(true);
  const [matchDeadline, setMatchDeadline] = useState(null);
  const [matchDeadlineDisabled, setMatchDeadlineDisabled] = useState(true);

  const [open, setOpen] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEmail = () => {
    setOpenEmail(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseEmail = () => {
    setOpenEmail(false);
  };

  const handleDeleteTrue = () => {
    setOpen(false);
    dispatch(deleteMatch(matchId));
  };

  const handleSendEmail = () => {
    setOpenEmail(false);
    dispatch(sendEmailNotification(matchId));
  };

  const dispatch = useDispatch();
  const { matchDetail, matchImages } = useSelector(
    (store) => store.matchDetailReducer
  );
  useEffect(() => {
    dispatch(getMatchDetail(matchId));
    dispatch(getMatchImages(matchId));
  }, []);

  useEffect(() => {
    setMatchName(matchDetail?.match_name ?? "");
    setMatchLocation(matchDetail?.location ?? "");
    setMatchDeadline(
      matchDetail?.registration_deadline
        ? new Date(matchDetail?.registration_deadline)
        : null
    );
  }, [matchDetail]);

  const convertTimestamp = (deadline) => {
    deadline.setHours(deadline.getHours() + 8);
    deadline.setSeconds(0);
    return deadline.toISOString().replace("T", " ").substring(0, 19);
  };

  const submitDeadlineChange = () => {
    if (!matchDeadlineDisabled) {
      const newDeadline = convertTimestamp(matchDeadline);
      dispatch(updateMatchDeadline(matchId, newDeadline));
    }
    setMatchDeadlineDisabled(false);
  };

  const handleFileChange = (event) => {
    setStatsFileName(event.target.value);
    setStatsFile(event.target.files ? event.target.files[0] : null);
  };

  const handleFileChangeV2 = (event) => {
    setStatsFileNameV2(event.target.value);
    setStatsFileV2(event.target.files ? event.target.files[0] : null);
  };

  const [toggleOpen, setToggleOpen] = useState(true);
  const handleToggleClose = () => {
    setToggleOpen(false);
  };
  const handleToggleOpen = () => {
    setToggleOpen(true);
  };

  let homePlayers = [];
  let awayPlayers = [];

  if (
    matchDetail?.home_team.match_stats.length ||
    matchDetail?.away_team.match_stats.length
  ) {
    homePlayers = [...matchDetail.home_team.match_stats];
    awayPlayers = [...matchDetail.away_team.match_stats];

    homePlayers.sort((a, b) => {
      if (a.back_number && b.back_number) {
        if (a.back_number < b.back_number) return -1;
        if (a.back_number > b.back_number) return 1;
      } else if (!a.back_number && b.back_number) return 1;
      else if (a.back_number && !b.back_number) return -1;
      else {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
      }
      return 0;
    });

    awayPlayers.sort((a, b) => {
      if (a.back_number && b.back_number) {
        if (a.back_number < b.back_number) return -1;
        if (a.back_number > b.back_number) return 1;
      } else if (!a.back_number && b.back_number) return 1;
      else if (a.back_number && !b.back_number) return -1;
      else {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
      }
      return 0;
    });
  }

  return (
    <div className="match-detail-wrapper">
      {/* TODO. change code after backend refactoring is done */}
      <div className="match-detail-score-wrapper">
        <div className="match-date-day">
          <span>{matchDetail?.scheduled_at.date}</span>
          <span>{matchDetail?.scheduled_at.day}</span>
        </div>
        <div className="match-league-game-name">
          <h3>{matchDetail?.league}</h3>
          <div className="match-name-wrapper">
            <TextField
              id="match-name-text-field"
              label="Match Name"
              variant="outlined"
              value={matchName}
              disabled={matchNameDisabled}
              sx={{ margin: "0.5rem", width: "20rem" }}
              onChange={(event) => {
                setMatchName(event.target.value);
              }}
            />
            <Button
              variant="outlined"
              sx={{ margin: "0.5rem" }}
              onClick={() => {
                if (!matchNameDisabled) {
                  dispatch(updateMatchName(matchId, matchName));
                }
                setMatchNameDisabled(!matchNameDisabled);
              }}
            >
              {matchNameDisabled ? "modify" : "update"}
            </Button>
          </div>
          <div className="match-location-wrapper">
            <TextField
              id="match-location-text-field"
              label="Location"
              variant="outlined"
              value={matchLocation}
              disabled={matchLocationDisabled}
              sx={{ margin: "0.5rem", width: "20rem" }}
              onChange={(event) => {
                setMatchLocation(event.target.value);
              }}
            />
            <Button
              variant="outlined"
              sx={{ margin: "0.5rem" }}
              onClick={() => {
                if (!matchLocationDisabled) {
                  dispatch(updateMatchLocation(matchId, matchLocation));
                }
                setMatchLocationDisabled(!matchLocationDisabled);
              }}
            >
              {matchLocationDisabled ? "modify" : "update"}
            </Button>
          </div>
          <div className="match-deadline-wrapper">
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField id="registration-deadline-text-field" {...props} />
                )}
                label="Registration Deadline"
                value={matchDeadline}
                format="yyyy-MM-dd HH:mm:ss"
                disabled={matchDeadlineDisabled}
                onChange={(newValue) => {
                  setMatchDeadline(newValue);
                }}
              />
            </LocalizationProvider>
            <Button
              variant="outlined"
              sx={{ margin: "0.5rem" }}
              onClick={submitDeadlineChange}
            >
              {matchDeadlineDisabled ? "modify" : "update"}
            </Button>
          </div>
        </div>
        <div className="match-time">
          <span>{matchDetail?.scheduled_at.time}</span>
        </div>
        <div className="match-team-with-score">
          <div className="match-team">
            {matchDetail?.home_team.name}
            {matchDetail?.home_win && (
              <div className="winner-indicator">
                👑
                <span>Winner</span>
              </div>
            )}
          </div>
          <div className="match-score-wrapper">
            {matchDetail?.home_score} : {matchDetail?.away_score}
          </div>
          <div className="match-team">
            {matchDetail?.away_team.name}
            {matchDetail?.away_win && (
              <div className="winner-indicator">
                👑
                <span>Winner</span>
              </div>
            )}
          </div>
        </div>
        <div className="match-detail-sync-winner-wrapper">
          <Button
            variant="outlined"
            className="match-sync-winner"
            onClick={() => {
              dispatch(syncMatchWinner(matchId));
            }}
          >
            Sync Winner from Scores
          </Button>
        </div>
        {matchDetail?.home_score != "-" ? null : (
          <div className="match-detail-forfeit-wrapper">
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="stats-upload-selector-label">Upload</InputLabel>
              <Input
                inputProps={{ accept: "*.*" }}
                multiple={false}
                type="file"
                value={statsFileName}
                onChange={handleFileChange}
              />
            </FormControl>
            {toggleOpen && (
              <Button
                disabled={statsFileName === ""}
                variant="outlined"
                sx={{ margin: "0.5rem" }}
                onClick={() => {
                  if (matchId != "") {
                    dispatch(uploadStats(matchId, statsFile));
                    handleToggleClose();
                  } else {
                    alert("Match ID is null. Please raise ticket to Dev team.");
                  }
                }}
              >
                upload
              </Button>
            )}
            {!toggleOpen && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!open}
                onClick={handleToggleOpen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            <FormControl sx={{ m: 1 }}>
              <InputLabel id="stats-upload-selector-label">Upload</InputLabel>
              <Input
                inputProps={{ accept: "*.*" }}
                multiple={false}
                type="file"
                value={statsFileNameV2}
                onChange={handleFileChangeV2}
              />
            </FormControl>
            {toggleOpen && (
              <Button
                disabled={statsFileNameV2 === ""}
                variant="outlined"
                sx={{ margin: "0.5rem" }}
                onClick={() => {
                  if (matchId != "") {
                    dispatch(uploadStats_V2(matchId, statsFileV2));
                    handleToggleClose();
                  } else {
                    alert("Match ID is null. Please raise ticket to Dev team.");
                  }
                }}
              >
                upload stats V2 With Hashnames
              </Button>
            )}
            {!toggleOpen && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!open}
                onClick={handleToggleOpen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}

            <FormControl sx={{ m: 1 }}>
              <InputLabel id="Forfeit-selector-label">Forfeit</InputLabel>
              <Select
                labelId="Forfeit-selector-label"
                id="Forfeit-selector"
                value={ffType}
                label="Forfeit"
                onChange={(event) => {
                  setFfType(event.target.value);
                }}
              >
                <MenuItem value={"HomeTeamForfeit"}>
                  {matchDetail?.home_team.name}
                </MenuItem>
                <MenuItem value={"AwayTeamForfeit"}>
                  {matchDetail?.away_team.name}
                </MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              className="match-detail-forfeit"
              onClick={() => {
                dispatch(forfeit(matchId, ffType));
              }}
            >
              Forfeit
            </Button>
          </div>
        )}
        <div className="match-detail-delete-wrapper">
          <Button
            variant="outlined"
            className="match-delete-forfeit"
            onClick={handleClickOpen}
          >
            Delete Match
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Match Delete Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you really want to delete this match?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleDeleteTrue} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <br></br>
        <div className="match-detail-email-notification-wrapper">
          <Button
            variant="outlined"
            className="match-email-notification"
            onClick={handleClickOpenEmail}
          >
            Send Notification Email
          </Button>
          <Dialog
            open={openEmail}
            onClose={handleCloseEmail}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Send Email Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Send emails to all players on season roster
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEmail}>Cancel</Button>
              <Button onClick={handleSendEmail} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className="match-stat-navigation-wrapper">
        <Link className="match-stat-navigator" to="">
          Content
        </Link>
        <Link className="match-stat-navigator" to="stats">
          Stats
        </Link>
        <Link className="match-stat-navigator" to="players">
          Players
        </Link>
        <Link className="match-stat-navigator" to="photos">
          Photos
        </Link>
      </div>
      <Routes>
        <Route
          path=""
          element={
            <MatchDetailContent
              homePlayers={homePlayers}
              awayPlayers={awayPlayers}
            />
          }
        />
        <Route path="/stats" element={<MatchDetailStat />} />
        <Route
          path="/players"
          element={
            <MatchDetailPlayers
              league={matchDetail?.league_abv}
              match={matchDetail?.match_name}
              homeTeam={matchDetail?.home_team}
              awayTeam={matchDetail?.away_team}
              homePlayers={homePlayers}
              awayPlayers={awayPlayers}
            />
          }
        />
        <Route
          path="/photos"
          element={
            <MatchDetailPhotos matchId={matchId} matchImages={matchImages} />
          }
        />
      </Routes>
    </div>
  );
};

export default MatchDetail;
