import React from "react";
import { Route, Routes } from "react-router-dom";
import PostList from "./PostList";
import PostWrite from "./PostWrite";
import PostDetail from "./PostDetail";

const Post = () => {
  return (
    <div className="post-page-wrapper">
      <Routes>
        <Route path="" element={<PostList />} />
        <Route path="create" element={<PostWrite />} />
        <Route path=":postId" element={<PostDetail />} />
        <Route path=":postId/edit" element={<PostWrite />} />
      </Routes>
    </div>
  );
};

export default Post;
