import axios from "axios";

export const LOGIN = "LOGIN";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_FAIL = "VALIDATE_TOKEN_FAIL";

export function login(username, password) {
  return (dispatch) => {
    var request_body = new FormData();
    request_body.append("username", username);
    request_body.append("password", password);
    axios
      .post(`${process.env.REACT_APP_API_PATH}/login`, request_body)
      .then((res) => {
        localStorage.setItem("sketch-token", res.data.access_token);
        dispatch({ type: LOGIN });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function validateToken() {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/admin/me`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        dispatch({
          type: VALIDATE_TOKEN,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        dispatch({ type: VALIDATE_TOKEN_FAIL });
        localStorage.clear();
        err.response.data.detail
          ? alert(err.response.data.detail)
          : alert("Login Failed. Please contact to DEV team.");
      });
  };
}
