import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MatchList.scss";
import { getMatches } from "../store/actions/match";
import { Link, useSearchParams } from "react-router-dom";
import MatchListItem from "../component/MatchListItem";

const MatchList = () => {
  const dispatch = useDispatch();
  const { matches, hasPrev, hasNext } = useSelector(
    (store) => store.matchReducer
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getMatches(searchParams.get("pages") ?? 0));
  }, [searchParams]);

  return (
    <div className="match-page-wrapper">
      <div className="match-list-navigator-wrapper">
        <Link
          className={
            hasPrev
              ? "match-list-navigator-item"
              : "match-list-navigator-item-disabled"
          }
          to={`?pages=${-1 + parseInt(searchParams.get("pages") ?? 0)}`}
        >
          {"<"}
        </Link>
        <Link
          className={
            hasNext
              ? "match-list-navigator-item"
              : "match-list-navigator-item-disabled"
          }
          to={`?pages=${1 + parseInt(searchParams.get("pages") ?? 0)}`}
        >
          {">"}
        </Link>
      </div>
      <div className="match-list-wrapper">
        {!matches.length ? (
          <div>no contents</div>
        ) : (
          matches.map((match) => {
            return <MatchListItem key={match.match_id} match={match} />;
          })
        )}
      </div>
    </div>
  );
};

export default MatchList;
