import axios from "axios";

export const GET_LEAGUES = "GET_LEAGUES";
export const GET_SEASONS = "GET_SEASONS";
export const ENROLL_SEASON = "ENROLL_SEASON";

export function getLeagues(parameter) {
  return (dispatch) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_PATH
        }/leagues?includes_all=true&sort_by_name=true${parameter ?? ""}`
      )
      .then((res) => {
        dispatch({ type: GET_LEAGUES, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export const updateLeague = (leagueId, name, abv, priority, category) => {
  const requestBody = {
    name: name,
    abv: abv,
    priority: priority,
    category: category,
  };
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_API_PATH}/leagues/${leagueId}`,
        requestBody,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then((res) => {
        alert(`${res.name} has been successfully updated!`);
        dispatch(getLeagues());
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
};

export function getSeasons(leagueId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/seasons?league_id=${leagueId}`)
      .then((res) => {
        dispatch({ type: GET_SEASONS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function enrollSeason(season_id, team_id) {
  return () => {
    const request_body = {
      season_id: season_id,
      team_id: team_id,
    };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/seasons/enroll`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        alert("Successfully enrolled to season!");
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Already enrolled or enroll Failed. DEV TEAM");
        console.log(err);
      });
  };
}

export function deleteFromSeason(season_id, team_id) {
  return () => {
    const request_body = {
      season_id: season_id,
      team_id: team_id,
    };
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/seasons/enroll`, {
        data: request_body,
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then((res) => {
        console.log(res.data);
        alert("Successfully removed team to season!");
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Team remove failed. contact DEV TEAM");
        console.log(err);
      });
  };
}
