import axios from "axios";

export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_FAIL = "GET_TEAMS_FAIL";

export function getTeams() {
  return (dispatch) => {
    dispatch({ type: GET_TEAMS });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams`)
      .then((res) => {
        dispatch({
          type: GET_TEAMS_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
        dispatch({ type: GET_TEAMS_FAIL });
      });
  };
}
