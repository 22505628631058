import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import Editor from "../component/Editor";
import { useDispatch, useSelector } from "react-redux";
import { createPost, editPost } from "../store/actions/post";
import "./PostWrite.scss";
import { postStatus } from "../consts/postStatus";
import {
  deleteImage,
  getImages,
  uploadThumbnail,
} from "../store/actions/postDetail";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getLeagues } from "../store/actions/matchCreate";

const PostWrite = () => {
  //dialog
  const [open, setOpen] = useState(false);

  //post
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(postStatus[1]);
  const [postId, setPostId] = useState("");
  const [leagueAbv, setLeagueAbv] = useState("All");
  const [thumbnail, setThumbnail] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const { leagues } = useSelector((store) => store.matchCreateReducer);

  //images
  const [imageUrlList, setImageUrlList] = useState([]);
  const [insertedImage, setInsertedImage] = useState("");
  const { images } = useSelector((store) => store.postDetailReducer);

  //edit
  const location = useLocation();
  const edit = location.state?.edit;
  const oldContent = location.state?.postDetail?.content;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLeagues());
  }, []);

  // Fetch postDetail when editing post
  edit &&
    useEffect(() => {
      setTitle(location.state?.postDetail.title);
      setStatus(location.state?.postDetail.status);
      setLeagueAbv(location.state?.postDetail.league_abv ?? "All");
      setPostId(location.state?.postDetail.id);
      setThumbnailUrl(location.state?.postDetail.thumbnail_url ?? "");
      dispatch(getImages(location.state?.postDetail.id));
      document.body.style.cursor = "default";
    }, []);

  // Update image list when images are fetched (edit only)
  useEffect(() => {
    if (window.location.pathname != "/posts/create") {
      setImageUrlList(images);
    }
  }, [images]);

  useEffect(() => {
    if (thumbnail === undefined) {
      dispatch(deleteImage(thumbnailUrl));
      setThumbnailUrl("");
    } else if (thumbnail !== "") {
      document.body.style.cursor = "wait";
      dispatch(uploadThumbnail(thumbnail, thumbnailUrl)).then((res) => {
        if (res.status === "success") setThumbnailUrl(res.image_url);
        document.body.style.cursor = "default";
      });
    }
  }, [thumbnail]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditorChange = (content) => {
    setContent(content);
  };

  /** Update image url list shown to user */
  const handleAddImage = (imageURL) => {
    setImageUrlList((imageUrlList) => [...imageUrlList, imageURL]);
  };

  const handleDeleteImage = (imageURL) => {
    dispatch(deleteImage(imageURL, postId)).then((res) => {
      res.status === "success"
        ? setImageUrlList(imageUrlList.filter((url) => url !== imageURL))
        : null;
    });
  };

  /** convert string to txt file */
  const createTxtFile = (content) => {
    return new Blob([content], { type: "text/plain" });
  };

  const validatePost = () => {
    if (title === "") {
      alert("Please enter a title");
      return false;
    }
    if (content === "" || content === "<p><br></p>") {
      alert("Please enter a content");
      return false;
    }
    return true;
  };

  return (
    <div className="post-editor-container">
      <div className="post-thumbnail-container">
        <div className="post-thumbnail-input">
          <span>Thumbnail</span>
          <Input
            inputProps={{ accepet: "image/*" }}
            multiple={false}
            type="file"
            onChange={(e) => {
              setThumbnail(e.target.files[0]);
            }}
          />
        </div>
        <div className="post-thumbnail">
          {thumbnailUrl.length ? (
            <img src={thumbnailUrl} />
          ) : (
            <span>Not Selected</span>
          )}
        </div>
      </div>
      <div className="post-title-status-wrapper">
        <FormControl>
          <InputLabel id="post-create-league-abv-label">League</InputLabel>
          <Select // League selection
            labelId="post-create-league-abv-label"
            id="post-create-league-abv"
            value={leagueAbv}
            label="League"
            onChange={(e) => {
              setLeagueAbv(e.target.value);
            }}
          >
            <MenuItem value={"All"} key={99}>
              All
            </MenuItem>
            {leagues.map((league, index) => {
              return (
                <MenuItem value={league.abv} key={index}>
                  {league.abv}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField // Title field
          id="post-create-title-text-field"
          label="Title"
          variant="outlined"
          required={true}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <FormControl>
          <InputLabel id="post-create-status-label">Status</InputLabel>
          <Select // Status selection
            labelId="post-create-status-label"
            id="post-create-status"
            value={status}
            label="Status"
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            {postStatus.map((status, index) => {
              return (
                <MenuItem value={status} key={index}>
                  {status}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <Editor
        content={edit ? oldContent : content}
        onChange={handleEditorChange}
        onAddImage={handleAddImage}
        insertedImage={insertedImage} // props.insertedImage to Editor component
        onInsertImage={setInsertedImage}
      />
      <div className="uploaded-images-container">
        <div className="uploaded-images-text">Images Uploaded</div>
        <div className="uploaded-image-files-container">
          {imageUrlList?.map((imageURL, index) => {
            return (
              // List of uploaded images
              <div className="uploaded-image-file-wrapper" key={index}>
                <img style={{ maxWidth: 70, height: 80 }} src={imageURL} />
                <Button
                  className="reuse-image-button"
                  style={{ fontSize: 5 }}
                  value={imageURL}
                  onClick={(e) => setInsertedImage(e.target.value)} // Reuse image src -> pass to Editor component
                >
                  INSERT
                </Button>
                <Button
                  className="delete-image-button"
                  style={{ fontSize: 5 }}
                  value={imageURL}
                  onClick={(e) => handleDeleteImage(e.target.value)}
                >
                  DELETE
                </Button>
              </div>
            );
          })}
        </div>
      </div>
      {edit ? ( // Buttons for edit and create
        <div>
          <Button
            variant="outlined"
            className="upload-post-edit-button"
            sx={{ margin: "0 auto 1rem" }}
            onClick={handleClickOpen}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            className="upload-post-edit-cancel-button"
            sx={{ margin: "0 auto 1rem" }}
            onClick={() => {
              navigate(-1); // 뒤로가기
            }}
          >
            Cancel
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Post Edit Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Confirm Edit?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleClose();
                  validatePost()
                    ? dispatch(
                        editPost(
                          location.state.postDetail.id,
                          leagueAbv,
                          title,
                          createTxtFile(content),
                          status,
                          imageUrlList,
                          thumbnailUrl
                        )
                      )
                    : null;
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div>
          <Button
            variant="outlined"
            className="upload-post-button"
            sx={{ margin: "0 auto 1rem" }}
            onClick={handleClickOpen}
          >
            Upload Post
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Post Upload Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Confirm upload?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleClose();
                  validatePost()
                    ? dispatch(
                        createPost(
                          leagueAbv,
                          title,
                          createTxtFile(content),
                          status,
                          imageUrlList,
                          thumbnailUrl
                        )
                      )
                    : null;
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default PostWrite;
