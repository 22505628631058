import React, { useEffect, useState } from "react";
import "./MatchCreate.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeagues,
  getSeasons,
  getTeams,
  createMatch,
} from "../store/actions/matchCreate";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { defaultMatchTypes } from "../consts/defaultMatchTypes";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";

const MatchCreate = () => {
  const dispatch = useDispatch();
  const [leagueId, setLeagueId] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [homeId, setHomeId] = useState("");
  const [awayId, setAwayId] = useState("");
  const [matchType, setMatchType] = useState("");
  const [location, setLocation] = useState("");
  const [matchName, setMatchName] = useState("");
  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [registrationDeadline, setRegistrationDeadline] = useState(new Date());

  const { leagues, seasons, teams } = useSelector(
    (store) => store.matchCreateReducer
  );
  useEffect(() => {
    dispatch(getLeagues());
  }, []);

  useEffect(() => {
    if (leagues.length) {
      setLeagueId(leagues[0].id);
    } else {
      setLeagueId("");
    }
  }, [leagues]);

  useEffect(() => {
    if (leagueId != "") {
      dispatch(getSeasons(leagueId));
      setSeasonId("");
    }
  }, [leagueId]);

  useEffect(() => {
    if (seasonId != "") {
      dispatch(getTeams(seasonId));
      setHomeId("");
      setAwayId("");
    }
  }, [seasonId]);

  useEffect(() => {
    if (seasons.length) {
      setSeasonId(seasons[0].id);
    } else {
      setSeasonId("");
    }
  }, [seasons]);

  const convertDatetimeToISO = (datetime) => {
    datetime.setHours(datetime.getHours() + 8);
    datetime.setSeconds(0);
    return datetime.toISOString();
  };

  const createMatchHandler = () => {
    if (homeId == awayId) {
      alert("Home and Away team should not be the same.");
      return;
    } else if (matchType == "") {
      alert("Match Type Missing.");
      return;
    } else if (location == "") {
      alert("Location Missing.");
      return;
    } else if (matchName == "") {
      alert("Match Name Missing.");
      return;
    }
    const scheduleISO = convertDatetimeToISO(new Date(scheduledAt.getTime()));
    const deadlineISO = convertDatetimeToISO(
      new Date(registrationDeadline.getTime())
    );

    if (scheduleISO < deadlineISO) {
      alert("Registration Deadline should be earlier than Match Schedule.");
      return;
    }

    dispatch(
      createMatch(
        seasonId,
        location,
        matchType,
        matchName,
        homeId,
        awayId,
        scheduleISO,
        deadlineISO
      )
    );
    setHomeId("");
    setAwayId("");
    setMatchName("");
    setMatchType("");
    setLocation("");
  };

  return (
    <div className="create-match-wrapper">
      <div className="input-wrapper">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="league-selector-label">League</InputLabel>
          <Select
            labelId="league-selector-label"
            id="league-selector"
            value={leagueId}
            label="League"
            onChange={(event) => {
              setLeagueId(event.target.value);
            }}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            {leagues.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.abv}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="season-selector-label">Season</InputLabel>
          <Select
            labelId="season-selector-label"
            id="season-selector"
            value={seasonId}
            label="Season"
            onChange={(event) => {
              setSeasonId(event.target.value);
            }}
          >
            {seasons.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="home-selector-label">Home</InputLabel>
          <Select
            labelId="home-selector-label"
            id="home-selector"
            value={homeId}
            label="Home"
            onChange={(event) => {
              setHomeId(event.target.value);
            }}
          >
            {teams?.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="away-selector-label">Away</InputLabel>
          <Select
            labelId="away-selector-label"
            id="away-selector"
            value={awayId}
            label="Away"
            onChange={(event) => {
              setAwayId(event.target.value);
            }}
          >
            {teams?.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {homeId != "" && awayId != "" ? (
          <>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="Match-type-selector-label">MatchType</InputLabel>
              <Select
                labelId="Match-type-selector-label"
                id="Match-type-selector"
                value={matchType}
                label="MatchType"
                onChange={(event) => {
                  setMatchType(event.target.value);
                }}
              >
                {defaultMatchTypes.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField id="match-name-text-field" {...props} />
                )}
                label="Date&Time"
                value={scheduledAt}
                format="yyyy-MM-dd HH:mm:ss"
                onChange={(newValue) => {
                  setScheduledAt(newValue);
                }}
              />
            </LocalizationProvider>
            <TextField
              id="location-text-field"
              label="Location"
              variant="outlined"
              required={true}
              value={location}
              onChange={(event) => {
                setLocation(event.target.value);
              }}
            />
            <TextField
              id="match-name-text-field"
              label="Match Name"
              variant="outlined"
              sx={{ margin: "0.5rem" }}
              required={true}
              value={matchName}
              onChange={(event) => {
                setMatchName(event.target.value);
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField id="registration-deadline-text-field" {...props} />
                )}
                label="Registration Deadline"
                value={registrationDeadline}
                format="yyyy-MM-dd HH:mm:ss"
                onChange={(newValue) => {
                  setRegistrationDeadline(newValue);
                }}
              />
            </LocalizationProvider>
          </>
        ) : null}
      </div>
      {homeId != "" && awayId != "" ? (
        <div className="create-match-button" onClick={createMatchHandler}>
          CREATE
        </div>
      ) : null}
    </div>
  );
};

export default MatchCreate;
