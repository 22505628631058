import axios from "axios";

export const GET_TEAM_DETAIL = "GET_TEAM_DETAIL";
export const GET_TEAM_DETAIL_FAIL = "GET_TEAM_DETAIL_FAIL";

export const GET_TEAM_CAPTAINS_SUCCESS = "GET_TEAM_CAPTAINS";
export const GET_TEAM_CAPTAINS_FAIL = "GET_TEAM_CAPTAINS_FAIL";

export const GET_TEAM_COACHES_SUCCESS = "GET_TEAM_COACHES";
export const GET_TEAM_COACHES_FAIL = "GET_TEAM_COACHES_FAIL";

export const GET_ENROLLED_SEASONS = "GET_ENROLLED_SEASONS";
export const GET_SEASON_ROSTER = "GET_SEASON_ROSTER";

export function getTeamDetail(teamId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${teamId}`)
      .then((res) => {
        dispatch({
          type: GET_TEAM_DETAIL,
          data: res.data,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
        dispatch({ type: GET_TEAM_DETAIL_FAIL });
      });
  };
}

export function uploadLogo(teamId, logoFile) {
  return () => {
    const request_body = new FormData();
    request_body.append("team_id", teamId);
    request_body.append("file", logoFile);

    axios
      .post(`${process.env.REACT_APP_API_PATH}/teams/logo`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("File is Successfully uploaded!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
}

export const getEnrolledSeasons = (teamId) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${teamId}/seasons`)
      .then((res) => {
        dispatch({ type: GET_ENROLLED_SEASONS, data: res.data });
      })
      .catch(() => {
        dispatch({ type: GET_ENROLLED_SEASONS, data: [] });
      });
  };
};

export const getSeasonRoster = (teamId, seasonId) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/teams/${teamId}/seasons/${seasonId}/players`
      )
      .then((res) => {
        dispatch({ type: GET_SEASON_ROSTER, data: res.data });
      })
      .catch(() => {
        dispatch({ type: GET_SEASON_ROSTER, data: [] });
      });
  };
};

export const clearSeasonRoster = () => {
  return (dispatch) => {
    dispatch({ type: GET_SEASON_ROSTER, data: [] });
  };
};

export const getTeamCaptains = (teamId) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${teamId}/captains`)
      .then((res) => {
        dispatch({
          type: GET_TEAM_CAPTAINS_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_TEAM_CAPTAINS_FAIL,
        });
        console.log(err.response);
      });
  };
};

export const addTeamCaptains = (teamId, newCaptainIds) => {
  return () => {
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/teams/${teamId}/captains`,
        newCaptainIds,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("New Captains are added successfully!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
};

export const deleteTeamCaptain = (captainId) => {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/teams/captains/${captainId}`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("Captain is deleted successfully!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
};

export const getTeamCoaches = (teamId) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/teams/${teamId}/coaches`)
      .then((res) => {
        dispatch({
          type: GET_TEAM_COACHES_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_TEAM_COACHES_FAIL,
        });
        console.log(err.response);
      });
  };
};

export const addTeamCoaches = (teamId, newCoachIds) => {
  return () => {
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/teams/${teamId}/coaches`,
        newCoachIds,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("New Coaches are added successfully!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
};

export const deleteTeamCoach = (coachId) => {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/teams/coaches/${coachId}`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("Coach is deleted successfully!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
};

export const changeTeamName = (teamId, teamName) => {
  return () => {
    axios
      .put(
        `${process.env.REACT_APP_API_PATH}/teams/${teamId}`,
        { name: teamName },
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("Changed Team Name to " + teamName);
        window.location.reload(false);
      })
      .catch((err) => {
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
};
