import axios from "axios";

export const GET_LEAGUES = "GET_LEAGUES";
export const GET_SEASONS = "GET_SEASONS";
export const GET_TEAMS = "GET_TEAMS";
export const CREATE_MATCH = "CREATE_MATCH";

export function getLeagues() {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/leagues?sort_by_name=true`)
      .then((res) => {
        dispatch({ type: GET_LEAGUES, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getSeasons(leagueId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/seasons?league_id=${leagueId}`)
      .then((res) => {
        dispatch({ type: GET_SEASONS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getTeams(seasonId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/seasons/${seasonId}/teams`)
      .then((res) => {
        dispatch({ type: GET_TEAMS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function createMatch(
  season_id,
  location,
  match_type,
  match_name,
  home_team_id,
  away_team_id,
  scheduled_at,
  registration_deadline
) {
  return () => {
    const request_body = {
      season_id: season_id,
      location: location,
      match_type: match_type,
      match_name: match_name,
      home_team_id: home_team_id,
      away_team_id: away_team_id,
      scheduled_at: scheduled_at,
      registration_deadline: registration_deadline,
    };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/matches`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then((res) => {
        alert("Match Created!");
        window.open(`${process.env.REACT_APP_PATH}/matches/${res.data.id}`);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Match Create FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}
