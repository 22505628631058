import React from "react";
import "./MatchListItem.scss";
import { useNavigate } from "react-router-dom";

const MatchListItem = ({ match }) => {
  const navigate = useNavigate();

  return (
    <div
      className="match-item"
      onClick={() => {
        navigate(`${match.match_id}`);
      }}
    >
      <div className="match-date-day">
        <span>{match.scheduled_at.date}</span>
        <span>{match.scheduled_at.day}</span>
      </div>
      <div className="match-league-game-name">
        <span>
          {match.league_name} | {match.match_name}
        </span>
      </div>
      <div className="match-time">
        <span>{match.scheduled_at.time}</span>
      </div>
      <div className="match-team-with-score">
        <div className="match-team">{match.home_team_name}</div>
        <div className="match-score-wrapper">
          {match.home_team_score} : {match.away_team_score}
        </div>
        <div className="match-team">{match.away_team_name}</div>
      </div>
    </div>
  );
};
export default MatchListItem;
