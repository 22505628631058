import React from "react";
import "./Header.scss";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { defaultMenu } from "../consts/defaultMenu";
const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="header-wrapper-sticky">
      <div className="header-wrapper">
        <div
          className="logo-wrapper"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={logo} className="Header-logo" alt="logo" />
        </div>
        {defaultMenu.map((item, index) => {
          return (
            <Link className="header-item" key={index} to={item.path}>
              {item.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Header;
