export const defaultMenu = [
  {
    name: "MATCHES",
    path: "matches",
  },
  {
    name: "NEW MATCH",
    path: "new-match",
  },
  {
    name: "PLAYERS",
    path: "players",
  },
  {
    name: "TEAMS",
    path: "teams",
  },
  {
    name: "LEAGUES",
    path: "leagues",
  },
  {
    name: "POSTS",
    path: "posts",
  },
  {
    name: "CREATE POSTS",
    path: "posts/create",
  },
  {
    name: "USERS",
    path: "users",
  },
];
