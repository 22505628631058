import axios from "axios";

export const GET_POST_DETAIL = "GET_POST_DETAIL";
export const GET_POST_DETAIL_SUCCESS = "GET_POST_DETAIL_SUCCESS";
export const GET_POST_DETAIL_FAIL = "GET_POST_DETAIL_FAIL";
export const GET_IMAGE_URLS = "GET_IMAGE_URLS";

export function getPostDetail(postId) {
  return (dispatch) => {
    dispatch({ type: GET_POST_DETAIL });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/posts/${postId}`)
      .then((res) => {
        dispatch({
          type: GET_POST_DETAIL_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_POST_DETAIL_FAIL });
      });
  };
}

export function deletePost(postId) {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/posts/${postId}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        window.location.href = "/posts";
      })
      .catch((err) => {
        console.log(err);
        alert("Error: " + err.response.data.detail);
      });
  };
}

export function getImages(postId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/posts/${postId}/images`)
      .then((res) => {
        dispatch({
          type: GET_IMAGE_URLS,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function uploadImage(image) {
  return async () => {
    const requestBody = new FormData();
    requestBody.append("file", image);
    document.body.style.cursor = "wait";
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH}/images/post_image`,
        requestBody,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
          },
        }
      )
      .then((res) => {
        document.body.style.cursor = "default";
        return res.data.image_url;
      })
      .catch((err) => {
        console.log(err);
        alert("Error: " + err.response.data.detail);
        throw err;
      });
  };
}

export function deleteImage(imageUrl, postId) {
  return async () => {
    const requestBody =
      postId !== undefined && postId !== ""
        ? {
            url: imageUrl,
            post_id: postId,
          }
        : {
            url: imageUrl,
          };
    return axios
      .post(
        `${process.env.REACT_APP_API_PATH}/images/post_image/delete`,
        requestBody,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        alert("Error: " + err.response.data.detail);
      });
  };
}

export function uploadThumbnail(file, thumbnailUrl) {
  return async () => {
    const responseBody = new FormData();
    responseBody.append("file", file);
    if (thumbnailUrl !== undefined && thumbnailUrl !== "") {
      responseBody.append("thumbnail_url", thumbnailUrl);
    }

    return axios
      .post(`${process.env.REACT_APP_API_PATH}/posts/thumbnail`, responseBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        alert("Error: " + err.response.data.detail);
      });
  };
}

export const clearPostDetail = () => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_DETAIL_FAIL,
    });
  };
};
