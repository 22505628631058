import React, { useEffect, useState } from "react";
import "./Login.scss";
import TextField from "@mui/material/TextField";
import { login, validateToken } from "../store/actions/login";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { isLoggedIn, validateAdmin } = useSelector(
    (store) => store.loginReducer
  );
  const loginHandler = () => {
    dispatch(login(username, password));
  };
  let navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && validateAdmin) {
      navigate(`/`);
    }
  }, [validateAdmin]);

  useEffect(() => {
    if (localStorage.getItem("sketch-token")) {
      dispatch(validateToken());
    }
  }, [localStorage.getItem("sketch-token")]);

  return (
    <div className="login-wrapper">
      <TextField
        id="login-username-text-field"
        label="Username"
        variant="outlined"
        sx={{ margin: "0.5rem" }}
        required={true}
        value={username}
        onChange={(event) => {
          setUsername(event.target.value);
        }}
      />
      <TextField
        id="login-password-text-field"
        label="Password"
        type="password"
        variant="outlined"
        sx={{ margin: "0.5rem" }}
        required={true}
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
        }}
      />
      <div className="login-button" onClick={loginHandler}>
        Login
      </div>
    </div>
  );
};

export default Login;
