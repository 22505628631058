import React from "react";
import "./Team.scss";
import { Route, Routes } from "react-router-dom";
import TeamDetail from "./TeamDetail";
import TeamList from "./TeamList";

const Team = () => {
  return (
    <div className="team-wrapper">
      <Routes>
        <Route path="" element={<TeamList />} />
        <Route path=":teamId/*" element={<TeamDetail />} />
      </Routes>
    </div>
  );
};

export default Team;
