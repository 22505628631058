import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSeasons } from "../store/actions/teamSeason";
import "./SeasonRosterRegister.scss";
import {
  uploadSeasonRoster,
  uploadSeasonRosterWithHashName,
} from "../store/actions/seasons";

const SeasonRosterRegister = () => {
  const dispatch = useDispatch();
  const { leagues, seasons } = useSelector(
    (store) => store.enrollSeasonReducer
  );
  const [leagueId, setLeagueId] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [rosterFile, setRosterFile] = useState(null);
  const [rosterFileName, setRosterFileName] = useState("");

  const handleFileChange = (event) => {
    setRosterFileName(event.target.value);
    setRosterFile(event.target.files[0]);
  };

  const handleLeagueChange = (e) => {
    setLeagueId(e.target.value);
    if (seasonId !== "") {
      setSeasonId("");
    }
  };

  const handleSeasonChange = (e) => {
    setSeasonId(e.target.value);
  };

  const registerRoster = () => {
    dispatch(uploadSeasonRoster(seasonId, rosterFile));
  };

  const registerRosterWithHashName = () => {
    dispatch(uploadSeasonRosterWithHashName(seasonId, rosterFile));
  };

  useEffect(() => {
    if (leagueId) {
      dispatch(getSeasons(leagueId));
    }
  }, [leagueId]);

  return (
    <div className="season-roster-register-form">
      <h2>Season Roster Registration</h2>
      <div className="input-wrapper">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="league-selector-label">League</InputLabel>
          <Select
            labelId="league-selector-label"
            id="league-selector"
            value={leagueId}
            label="League"
            onChange={handleLeagueChange}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            {leagues?.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.abv}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1 }}>
          <InputLabel id="season-selector-label">Season</InputLabel>
          <Select
            labelId="season-selector-label"
            id="season-selector"
            value={seasonId}
            label="Season"
            onChange={handleSeasonChange}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            {seasons.length ? (
              seasons.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={""}>Please Select League First</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div className="season-roster-upload">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="season-upload-selector-label">Upload</InputLabel>
          <Input
            inputProps={{ accept: "*.*" }}
            multiple={false}
            type="file"
            value={rosterFileName}
            onChange={handleFileChange}
          />
        </FormControl>
        <Button
          variant="outlined"
          sx={{ m: 1 }}
          disabled={seasonId === "" || !rosterFile}
          onClick={registerRoster}
        >
          upload
        </Button>
        <Button
          variant="outlined"
          sx={{ m: 1 }}
          disabled={seasonId === "" || !rosterFile}
          onClick={registerRosterWithHashName}
        >
          upload(name with hash, e.g. Kiwhan#421a)
        </Button>
      </div>
    </div>
  );
};

export default SeasonRosterRegister;
