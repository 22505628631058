import React, { useState } from "react";
import "./PlayerProfilePhoto.scss";
import { Button, Input } from "@mui/material";
import { useDispatch } from "react-redux";
import { uploadPlayerPhoto, deletePlayerPhoto } from "../store/actions/player";

const PlayerProfilePhoto = ({ player }) => {
  const dispatch = useDispatch();
  const [profilePhotoName, setProfilePhotoName] = useState("");
  const [profilePhotoFile, setProfilePhotoFile] = useState("");

  const handlePlayerProfilePhotoChange = (event) => {
    setProfilePhotoName(event.target.value);
    setProfilePhotoFile(event.target.files ? event.target.files[0] : null);
  };

  return (
    //remove table
    <div className="player-profile-photo">
      <div className="player-profile-photo-file-wrapper">
        {player.profile_photo && (
          <img
            src={player.profile_photo}
            className="player-profile-photo-file"
          />
        )}
        <Input
          inputProps={{ accept: "image/*" }}
          multiple={false}
          type="file"
          value={profilePhotoName}
          onChange={handlePlayerProfilePhotoChange}
        />
        <Button
          disabled={profilePhotoName === ""}
          variant="outlined"
          sx={{ margin: "0.5rem" }}
          onClick={() => {
            dispatch(uploadPlayerPhoto(player.id, profilePhotoFile));
          }}
        >
          upload
        </Button>
        <Button
          disabled={profilePhotoName != ""}
          variant="outlined"
          sx={{ margin: "0.5rem", backgroundColor: "red" }}
          onClick={() => {
            dispatch(deletePlayerPhoto(player.id));
          }}
        >
          delete
        </Button>
      </div>
    </div>
  );
};

export default PlayerProfilePhoto;
