import React, { useState, useEffect } from "react";
import "./ShootingChartForm.scss";
import { Input } from "@mui/material";

const ShootingChartForm = ({
  matchStat,
  onChange,
  index,
  deviceSize,
  side,
}) => {
  const [shootChartFilename, setShootChartFilename] = useState("");
  const [device, setDevice] = useState(null);
  const [deviceConstant, setDeviceConstant] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (deviceSize) {
      setDevice(deviceSize);
    }
  }, [deviceSize]);

  function drawImageActualSize() {
    const canvas = document.querySelector(
      "#" + side + "-shooting-chart-" + index
    );
    const context = canvas.getContext("2d");

    let cutWidth =
      this.naturalWidth -
      deviceConstant.cutWidth *
        (this.naturalWidth / deviceConstant.deviceWidth);
    let cutHeight =
      this.naturalHeight -
      deviceConstant.cutHeight *
        (this.naturalHeight / deviceConstant.deviceHeight);

    this.width = cutWidth;
    this.height = cutHeight;

    canvas.width =
      this.height -
      deviceConstant.canvasWidth *
        (this.naturalHeight / deviceConstant.deviceHeight);
    canvas.height = this.width;

    context.save();
    context.translate(this.width / 2, this.height / 2);
    context.rotate((90 * Math.PI) / 180);
    context.translate((-4 * this.width) / 7, -this.height / 2);

    let startWidth =
      deviceConstant.startWidth *
      (this.naturalWidth / deviceConstant.deviceWidth);
    let startHeight =
      deviceConstant.startHeight *
      (this.naturalHeight / deviceConstant.deviceHeight);

    context.drawImage(
      this,
      startWidth,
      startHeight,
      cutWidth,
      cutHeight,
      0,
      0,
      this.width,
      this.height
    );
    context.restore();

    let dataURL = canvas.toDataURL();
    dataURLtoFile(dataURL);
    canvas.setAttribute("style", "display: block");
  }

  const handleShootingChartChange = (event) => {
    setShootChartFilename(event.target.value);
    setSelectedFile(event.target.files[0]);
  };

  const dataURLtoFile = (dataURL) => {
    fetch(dataURL)
      .then((res) => res.blob())
      .then((blob) => {
        let file = new File([blob], "shooting_chart.png", {
          type: "image/png",
        });
        setFile(file);
      });
  };

  useEffect(() => {
    if (device) {
      let constant = new Object({
        deviceWidth: 0,
        deviceHeight: 0,
        cutWidth: 0,
        cutHeight: 0,
        startWidth: 0,
        startHeight: 0,
        canvasWidth: 0,
      });
      if (device === "10.9") {
        constant.deviceWidth = 2360;
        constant.deviceHeight = 1640;
        constant.cutWidth = 1100;
        constant.cutHeight = 215;
        constant.startWidth = 20;
        constant.startHeight = 193;
        constant.canvasWidth = 100;
      } else if (device === "10.5") {
        constant.deviceWidth = 2224;
        constant.deviceHeight = 1668;
        constant.cutWidth = 950;
        constant.cutHeight = 215;
        constant.startWidth = 25;
        constant.startHeight = 203;
        constant.canvasWidth = 100;
      } else if (device === "11.9") {
        constant.deviceWidth = 2560;
        constant.deviceHeight = 1920;
        constant.cutWidth = 1060;
        constant.cutHeight = 230;
        constant.startWidth = 20;
        constant.startHeight = 210;
        constant.canvasWidth = 100;
      }
      setDeviceConstant(constant);
    }
  }, [device]);

  useEffect(() => {
    if (deviceConstant && selectedFile) {
      let url = URL.createObjectURL(selectedFile);
      const img = new Image();
      img.src = url;
      img.onload = drawImageActualSize;
    }
  }, [deviceConstant, selectedFile]);

  useEffect(() => {
    if (file) {
      onChange(matchStat.id, file);
    }
  }, [file]);

  return (
    <div className="match-detail-shooting-chart">
      <div>{`${
        matchStat.back_number
          ? matchStat.back_number === 100
            ? "00"
            : matchStat.back_number
          : "-"
      } ${matchStat.name}`}</div>
      <div className="match-detail-shooting-chart-file-wrapper">
        <Input
          inputProps={{ accept: "image/*" }}
          multiple={false}
          type="file"
          value={shootChartFilename}
          onChange={(event) => {
            handleShootingChartChange(event);
          }}
        />
        <div className="shooting-chart-images">
          <div className="uploaded-image-wrapper">
            <span>Uploaded Image</span>
            {matchStat.shooting_chart ? (
              <img
                src={matchStat.shooting_chart}
                className="match-detail-shooting-chart-file"
              />
            ) : null}
          </div>
          <div className="canvas-wrapper">
            <span>Cropped Image!</span>
            <canvas id={`${side}-shooting-chart-${index}`}></canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShootingChartForm;
