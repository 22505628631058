import {
  GET_USER_REQUESTS,
  GET_USER_BY_USERNAME,
  GET_USER_BY_USER_TYPE,
} from "../actions/user";

const initialState = {
  startPage: 1,
  endPage: 0,
  userRequests: [],
  users: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUESTS:
      return {
        ...state,
        startPage: action.data.start_page,
        endPage: action.data.end_page,
        userRequests: action.data.conn_reqs,
      };
    case GET_USER_BY_USERNAME:
      return {
        ...state,
        users: action.data,
      };
    case GET_USER_BY_USER_TYPE:
      return {
        ...state,
        users: action.data,
      };
    default:
      return state;
  }
}
