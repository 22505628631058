import React, { useEffect } from "react";
import { useParams } from "react-router";
import "./MatchLive.scss";
import { sampleRoster, sampleSubs } from "../consts/sampleData";

const MatchLive = () => {
  const { matchId } = useParams();
  useEffect(() => {
    console.log(matchId);
  }, []);

  return (
    <div className="match-live-wrapper">
      <div className="match-live-content">
        <div className="match-live-home">
          <h3>Roster</h3>
          <table>
            <tbody>
              {sampleRoster.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="cell-back-number">{item.num}</td>
                    <td className="cell-player-name">{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h3>Subs</h3>
          <table>
            <tbody>
              {sampleSubs.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="cell-back-number">{item.num}</td>
                    <td className="cell-player-name">{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="match-live-control-wrapper">
          <div className="match-live-score-log">
            <div className="control-log-wrapper">Logs to be shown</div>
            <div className="control-score-borrd">13 : 20</div>
          </div>
          <div className="match-live-control">
            <div className="control-item-default control-item-success">
              <span>2PT</span>
              <span>Made</span>
            </div>
            <div className="control-item-default control-item-fail">
              <span>2PT</span>
              <span>Missed</span>
            </div>
            <div className="control-item-default control-item-success">
              <span>3PT</span>
              <span>Made</span>
            </div>
            <div className="control-item-default control-item-fail">
              <span>3PT</span>
              <span>Missed</span>
            </div>
            <div className="control-item-default control-item-success">
              <span>Free Throw</span>
              <span>Made</span>
            </div>
            <div className="control-item-default control-item-fail">
              <span>Free Throw</span>
              <span>Missed</span>
            </div>
          </div>
          <div className="match-live-control">
            <div className="control-item-default control-item">
              <span>Off</span>
              <span>Rebound</span>
            </div>
            <div className="control-item-default control-item">
              <span>Def</span>
              <span>Rebound</span>
            </div>
            <div className="control-item-default control-item">
              <span>Block</span>
            </div>
            <div className="control-item-default control-item">
              <span>Assist</span>
            </div>
            <div className="control-item-default control-item">
              <span>Steal</span>
            </div>
            <div className="control-item-default control-item">
              <span>Turnover</span>
            </div>
          </div>
        </div>

        <div className="match-live-away">
          <h3>Roster</h3>
          <table>
            <tbody>
              {sampleRoster.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="cell-back-number">{item.num}</td>
                    <td className="cell-player-name">{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <h3>Subs</h3>
          <table>
            <tbody>
              {sampleSubs.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="cell-back-number">{item.num}</td>
                    <td className="cell-player-name">{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MatchLive;
