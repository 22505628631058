import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRequests } from "../store/actions/user";
import UserRequest from "./UserRequest";
import "./UserRequestList.scss";

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>Request Date</th>
        <th>User</th>
        <th>Name</th>
        <th>Player</th>
      </tr>
    </thead>
  );
};

const UserRequests = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLast, setIsLast] = useState(false);
  const { startPage, endPage, userRequests } = useSelector(
    (store) => store.userReducer
  );

  useEffect(() => {
    dispatch(getUserRequests(1));
  }, []);

  useEffect(() => {
    if (endPage % 10 === 0) {
      setIsLast(true);
    }
  }, [endPage]);

  const handlePageChange = (page) => {
    dispatch(getUserRequests(page));
    setCurrentPage(page);
  };

  return (
    <div className="connection-table-container">
      <h1>Connection Requests List</h1>
      {userRequests ? (
        <table className="connection-table">
          <TableHeader />
          <tbody>
            {userRequests.map((request) => {
              return <UserRequest key={request.id} request={request} />;
            })}
          </tbody>
        </table>
      ) : (
        <p>No Requests</p>
      )}

      {/* Page Buttons */}
      <div className="pagination-container" style={{ marginTop: 10 }}>
        {startPage !== 1 && (
          <button
            className="pagination-button"
            onClick={() => {
              handlePageChange(startPage - 1);
            }}
          >
            {"<"}
          </button>
        )}
        {Array(endPage - startPage + 1)
          .fill()
          .map((_, i) => {
            return (
              <button
                key={i}
                className={`pagination-button ${
                  currentPage === startPage + i ? "active" : ""
                }`}
                onClick={() => {
                  handlePageChange(startPage + i);
                }}
                disabled={currentPage === startPage + i}
              >
                {startPage + i}
              </button>
            );
          })}
        {!isLast && (
          <button
            className="pagination-button"
            onClick={() => {
              handlePageChange(endPage + 1);
            }}
          >
            {">"}
          </button>
        )}
      </div>
    </div>
  );
};

export default UserRequests;
