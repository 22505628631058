import {
  GET_POST_DETAIL,
  GET_POST_DETAIL_SUCCESS,
  GET_POST_DETAIL_FAIL,
  GET_IMAGE_URLS,
} from "../actions/postDetail";

const initialState = {
  postDetail: null,
  images: [],
  postLoading: false,
};

export default function postDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_POST_DETAIL:
      return {
        ...state,
        postLoading: true,
      };
    case GET_POST_DETAIL_SUCCESS:
      return {
        ...state,
        postDetail: action.data,
        postLoading: false,
      };
    case GET_POST_DETAIL_FAIL:
      return {
        ...state,
        postDetail: null,
        postLoading: false,
      };
    case GET_IMAGE_URLS:
      return {
        ...state,
        images: action.data,
      };
    default:
      return state;
  }
}
