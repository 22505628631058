import React, { useRef, useState } from "react";
import { userSearchType } from "../consts/userSearchType";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "./UserSearch.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUserBySearchType } from "../store/actions/user";

const TableHeader = () => {
  return (
    <thead className="search-table-header">
      <tr>
        <th>Username (아이디)</th>
        <th>Name</th>
        <th>Nickname</th>
        <th>Email</th>
        <th>Player ID</th>
      </tr>
    </thead>
  );
};

const UserSearch = () => {
  const dispatch = useDispatch();
  const [searchType, setSearchType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { users } = useSelector((store) => store.userReducer);
  const prevController = useRef();

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    handleSearchSubmit(e.target.value);
  };

  const handleSearchSubmit = (value) => {
    if (prevController.current) {
      prevController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;
    prevController.current = controller;

    dispatch(getUserBySearchType(searchType, value, signal));
  };

  return (
    <div className="user-search-wrapper">
      <h1>User Search</h1>
      <div className="user-search-bar-wrapper">
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="user-search-type-label">Search Type</InputLabel>
          <Select
            labelId="search-type-selector-label"
            id="search-type-selector"
            value={searchType}
            label="Search Type"
            onChange={handleSearchTypeChange}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            {userSearchType?.map((type, index) => {
              return (
                <MenuItem value={type} key={index}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          id="search-value-text-field"
          label={`Search ${searchType}`}
          variant="outlined"
          sx={{ margin: "0.5rem", minWidth: 250 }}
          value={searchValue}
          onChange={handleSearchValueChange}
        />
      </div>

      <div className="user-search-result-wrapper">
        {users.length === 0 &&
          (searchType === "Player Name" ? (
            <h2>There is no user connected to {searchValue}</h2>
          ) : (
            <h2>There is no such user</h2>
          ))}

        <div className="user-search-result-table-wrapper">
          {users.length > 0 && searchValue !== "" && (
            <table className="user-search-result-table">
              <TableHeader />
              <tbody>
                {users.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{user.username}</td>
                      <td>{user.name}</td>
                      <td>{user.nickname}</td>
                      <td>{user.email}</td>
                      <td>{user.player_id}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
