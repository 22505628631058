import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getLeagues } from "../store/actions/teamSeason";
import LeagueCreate from "../component/LeagueCreate";
import SeasonCreate from "../component/SeasonCreate";
import SeasonRosterRegister from "../component/SeasonRosterRegister";
import LeagueSearch from "../component/LeagueSearch";

const Leagues = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLeagues());
  }, []);

  return (
    <div style={{ color: "white" }}>
      <LeagueSearch />
      <LeagueCreate />
      <SeasonCreate />
      <SeasonRosterRegister />
    </div>
  );
};

export default Leagues;
