import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { leagueCategory } from "../consts/leagueCategory";
import { useDispatch } from "react-redux";
import { updateLeague } from "../store/actions/teamSeason";

const LeagueDetail = ({ league }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [abv, setAbv] = useState("");
  const [priority, setPriority] = useState(0);
  const [category, setCategory] = useState("");
  const [inputsDisabled, setInputsDisabled] = useState(true);

  useEffect(() => {
    if (league) {
      setName(league.name);
      setAbv(league.abv);
      setPriority(league.priority);
      setCategory(league.category);
      setInputsDisabled(true);
    }
  }, [league]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleAbvChange = (e) => {
    setAbv(e.target.value);
  };

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const validateUpdate = (name, abv) => {
    if (name.length === 0) {
      alert("Please enter the name");
      return false;
    }

    if (abv.length === 0) {
      alert("Please enter the abbreviation");
      return false;
    }

    return true;
  };

  const handleSubmitClick = () => {
    if (inputsDisabled) {
      setInputsDisabled(false);
    } else if (!inputsDisabled && validateUpdate(name, abv)) {
      dispatch(updateLeague(league.id, name, abv, priority, category));
      setInputsDisabled(true);
    }
  };

  return (
    <tr className="league-detail-table-rows">
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={name || ""}
          disabled={inputsDisabled}
          onChange={handleNameChange}
          type="string"
        />
      </th>
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={abv || ""}
          disabled={inputsDisabled}
          onChange={handleAbvChange}
        />
      </th>
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={priority || 0}
          disabled={inputsDisabled}
          onChange={handlePriorityChange}
          type="number"
        />
      </th>
      <th>
        <FormControl sx={{ minWidth: 135 }}>
          <InputLabel id="league-category-selector-label">
            League Category
          </InputLabel>
          <Select
            labelId="league-category-selector-label"
            id="league-category-selector"
            value={category}
            label="League Category"
            onChange={handleCategoryChange}
            disabled={inputsDisabled}
          >
            {leagueCategory.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </th>

      <th>
        <Button variant="outlined" onClick={handleSubmitClick}>
          {inputsDisabled ? "modify" : "update"}
        </Button>
      </th>
    </tr>
  );
};

export default LeagueDetail;
