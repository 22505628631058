import React, { useEffect, useState } from "react";
import "./Player.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeagues,
  getSeasons,
  getTeams,
  getPlayers,
  getPlayer,
  createPlayer,
} from "../store/actions/player";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import PlayerUpdateRow from "../component/PlayerUpdateRow";
import PlayerProfilePhoto from "../component/PlayerProfilePhoto";
import SeasonPlayerSelect from "../component/SeasonPlayerSelect";
import PlayerSearch from "../component/PlayerSearch";

const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th>NAME(Hash Name)</th>
        <th>WEIGHT</th>
        <th>HEIGHT</th>
        <th>QUOTE</th>
        {/* <th>BIRTHDATE</th> */}
      </tr>
    </thead>
  );
};

const Player = () => {
  const dispatch = useDispatch();
  const [leagueId, setLeagueId] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [playerId, setPlayerId] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [isPlayerSelected, setIsPlayerSelected] = useState(false);

  const { leagues, seasons, teams, players, player } = useSelector(
    (store) => store.playerReducer
  );

  useEffect(() => {
    dispatch(getLeagues());
  }, []);

  useEffect(() => {
    if (leagues.length) {
      setLeagueId(leagues[0].id);
    } else {
      setLeagueId("");
    }
  }, [leagues]);

  useEffect(() => {
    if (leagueId != "") {
      dispatch(getSeasons(leagueId));
      setSeasonId("");
    }
  }, [leagueId]);

  useEffect(() => {
    if (seasonId != "") {
      dispatch(getTeams(seasonId));
      setTeamId("");
    }
  }, [seasonId]);

  useEffect(() => {
    if (seasons.length) {
      setSeasonId(seasons[0].id);
    } else {
      setSeasonId("");
    }
  }, [seasons]);

  useEffect(() => {
    if (teamId != "") {
      dispatch(getPlayers(teamId, seasonId));
      setPlayerId("");
    }
  }, [seasonId, teamId]);

  useEffect(() => {
    if (playerId != "") {
      setIsPlayerSelected(true);
      dispatch(getPlayer(playerId));
    }

    if (isPlayerSelected && playerId === "") {
      setIsPlayerSelected(false);
    }
  }, [playerId]);

  const playerHandler = () => {
    dispatch(createPlayer(playerName));

    setLeagueId("");
    setSeasonId("");
    setTeamId("");
    setPlayerId("");
    setPlayerName("");
  };

  return (
    <div className="player-wrapper">
      <div className="input-wrapper">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="league-selector-label">League</InputLabel>
          <Select
            labelId="league-selector-label"
            id="league-selector"
            value={leagueId}
            label="League"
            onChange={(event) => {
              setLeagueId(event.target.value);
            }}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            {leagues.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.abv}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="season-selector-label">Season</InputLabel>
          <Select
            labelId="season-selector-label"
            id="season-selector"
            value={seasonId}
            label="Season"
            onChange={(event) => {
              setSeasonId(event.target.value);
            }}
          >
            {seasons.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="team-selector-label">Team</InputLabel>
          <Select
            labelId="team-selector-label"
            id="team-selector"
            value={teamId}
            label="Team"
            onChange={(event) => {
              setTeamId(event.target.value);
            }}
          >
            {teams?.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="player-selector-label">Player</InputLabel>
          <Select
            labelId="player-selector-label"
            id="player-selector"
            value={playerId}
            label="player"
            onChange={(event) => {
              setPlayerId(event.target.value);
            }}
          >
            {players.map((item, index) => {
              return (
                <MenuItem value={item.player_id} key={index}>
                  {item.player_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      <div className="player-detail-cotainer">
        <table className="player-detail-tables">
          <TableHeader />
          <tbody>
            {isPlayerSelected === true ? (
              <PlayerUpdateRow player={player} />
            ) : null}
          </tbody>
        </table>
      </div>
      <div>
        {isPlayerSelected === false ? (
          <h4 style={{ color: "white", marginTop: "4rem" }}>
            Please Select A Player to <strong>EDIT</strong> or{" "}
            <strong>MOVE STATS</strong> to Another Player
          </h4>
        ) : (
          // remove table
          <h3 style={{ color: "white", marginTop: "4rem" }}>
            Move this season roster stats to another roster(hashname/playerId)
          </h3>
        )}
      </div>
      <div className="season-player-update-container">
        {isPlayerSelected && (
          <SeasonPlayerSelect playerId={playerId} seasonId={seasonId} />
        )}
      </div>
      <div className="player-detail-cotainer">
        {isPlayerSelected && <PlayerProfilePhoto player={player} />}
      </div>
      <PlayerSearch />
      <div className="player-create-cotainer">
        <div>
          <h2 style={{ color: "white", marginTop: "2rem" }}>
            Player Registration
          </h2>
        </div>
        <div className="player-create-button">
          <TextField
            id="player-name-text-field"
            label="Player Name"
            variant="outlined"
            sx={{ margin: "0.5rem" }}
            required={true}
            value={playerName}
            onChange={(event) => {
              setPlayerName(event.target.value);
            }}
          />
          <div className="create-player-button" onClick={playerHandler}>
            ADD NEW PLAYER
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;
