import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { getSeasonRoster } from "../store/actions/teamDetail";
import SeasonRosterTable from "./SeasonRosterTable";

const SeasonRosterForm = ({ teamId }) => {
  const dispatch = useDispatch();
  const [selectedSeasonId, setselectedSeasonId] = useState("");
  const { enrolledSeasons } = useSelector((store) => store.teamDetailReducer);

  useEffect(() => {
    if (selectedSeasonId !== "") {
      dispatch(getSeasonRoster(teamId, selectedSeasonId));
    }
  }, [selectedSeasonId]);

  return (
    <div className="team-detail-control-box">
      <div className="team-season-rosters">
        <div className="team-season-rosters-wrapper">
          <h3>Season Roster (Only Back Number Editable)</h3>
          <div className="team-season-roster-select">
            <FormControl sx={{ m: 1, minWidth: 400 }}>
              <InputLabel id="season-selector-label">Select Season</InputLabel>
              <Select
                labelId="season-selector-label"
                id="season-selector"
                value={selectedSeasonId}
                label="Select Season"
                onChange={(event) => {
                  setselectedSeasonId(event.target.value);
                }}
              >
                {enrolledSeasons?.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.league_name} - {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedSeasonId && (
            <SeasonRosterTable
              teamId={teamId}
              selectedSeasonId={selectedSeasonId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SeasonRosterForm;
