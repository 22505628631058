import React, { useState } from "react";
import "./SeasonPlayerSelect.scss";
import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";

import { MoveSeasonPlayer } from "../store/actions/player";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const SeasonPlayerSelect = ({ playerId, seasonId }) => {
  const dispatch = useDispatch();
  const [playerHashName, setPlayerHashName] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TextField
        id="player-detail-text-field"
        variant="outlined"
        sx={{ margin: "0.2rem" }}
        value={playerHashName}
        onChange={(event) => {
          setPlayerHashName(event.target.value);
        }}
        type="string"
      />
      <Button
        variant="outlined"
        className="season-player-change-button"
        onClick={handleClickOpen}
      >
        update
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Season Player modification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to change the season player record to go to :
            {playerHashName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(MoveSeasonPlayer(playerId, seasonId, playerHashName));
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={handleClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SeasonPlayerSelect;
