import React from "react";
import MatchRoster from "../component/MatchRoster";
import "./MatchDetailPlayers.scss";
import StartingFive from "../component/StartingFive";
import Thumbnail from "../component/Thumbnail";

const MatchDetailPlayers = (props) => {
  return (
    <div className="match-detail-players-wrapper">
      <div className="match-detail-players-list">
        <div className="match-detail-home-players">
          <div className="match-detail-home-team">{props.homeTeam?.name}</div>
          <MatchRoster players={props.homePlayers} />
        </div>
        <div className="match-detail-away-players">
          <div className="match-detail-away-team">{props.awayTeam?.name}</div>
          <MatchRoster players={props.awayPlayers} />
        </div>
      </div>
      <span className="generator-title">Starting Five</span>
      <StartingFive props={props} />
      <span className="generator-title">Thumbnail</span>
      <Thumbnail />
    </div>
  );
};

export default MatchDetailPlayers;
