import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  getPostDetail,
  deletePost,
  clearPostDetail,
} from "../store/actions/postDetail";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "./PostDetail.scss";
import DOMPurify from "dompurify";
import convertURLtoEmbed from "../component/convertURLtoEmbed";

const PostDetail = () => {
  const { postId } = useParams();
  const { postDetail } = useSelector((store) => store.postDetailReducer);
  const dispatch = useDispatch();
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [content, setContent] = useState("");

  //allowed attributes of iframe tag
  const attributes = [
    "frameBoreder",
    "allow",
    "allowfullscreen",
    "scrolling",
    "allowtransparency",
  ];

  useEffect(() => {
    dispatch(getPostDetail(postId));

    //reset content on component unmount
    return () => {
      setContent("");
      dispatch(clearPostDetail());
      window.location.reload(); //refresh page for instagram embed
    };
  }, []);

  useEffect(() => {
    if (postDetail) {
      setContent(convertURLtoEmbed(postDetail.content));
    }
  }, [postDetail]);

  const handleEditToggle = () => {
    editOpen ? setEditOpen(false) : setEditOpen(true);
  };

  const handleDeleteToggle = () => {
    deleteOpen ? setDeleteOpen(false) : setDeleteOpen(true);
  };

  //Sanitize content for security
  return (
    <div className="post-detail-page-container">
      <div className="post-detail-container">
        <div className="post-detail-header">
          <div className="post-detail-title">{postDetail?.title}</div>
          <div className="post-detail-wrapper">
            <div className="post-detail-abv-author-wrapper">
              <span className="post-deatil-abv post-detail">
                {postDetail?.league_abv ?? "All"}
              </span>
              <span className="post-detail-author post-detail">
                {postDetail?.username}
              </span>
            </div>
            <div className="post-detail-datetime-status-wrapper">
              <span className="post-detail-datetime post-detail">
                {postDetail?.created_at.date +
                  " " +
                  postDetail?.created_at.time}
              </span>
              <span className="post-detail-status post-detail">
                Post Status: {postDetail?.status}
              </span>
            </div>
          </div>
        </div>
        <div className="post-detail-content-wrapper">
          <div
            className="post-detail-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content, {
                ADD_TAGS: ["iframe"],
                ADD_ATTR: attributes,
              }), //allow iframe tag to be rendered
            }}
          ></div>
        </div>
      </div>
      <div className="post-detail-button-wrapper">
        <Button className="post-detail-edit-button">
          <Link
            to="edit" //Reuse <PostWrite /> by passing postDetail
            state={{
              edit: true,
              postDetail: postDetail,
            }}
            onClick={() => {
              document.body.style.cursor = "wait";
            }}
          >
            Edit
          </Link>
        </Button>
        <Button onClick={handleDeleteToggle}>Delete</Button>
      </div>
      <Dialog
        open={editOpen}
        onClose={handleEditToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Post Edit Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirm edit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditToggle}>Cancel</Button>
          <Button onClick={handleEditToggle}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteToggle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Post Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirm Delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteToggle}>Cancel</Button>
          <Button
            onClick={() => {
              handleDeleteToggle();
              dispatch(deletePost(postId));
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PostDetail;
