import axios from "axios";

export const GET_USER_REQUESTS = "GET_USER_REQUESTS";
export const GET_USER_BY_USERNAME = "GET_USER_BY_USERNAME";

export const GET_USER_BY_USER_TYPE = "GET_USER_BY_USER_TYPE";

export const getUserRequests = (currentPage) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/admin/requests?current_page=${currentPage}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: GET_USER_REQUESTS,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

export const acceptConnectionRequest = (requestId) => {
  return () => {
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/admin/requests/${requestId}`,
        null,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
            "Content-Length": 0,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(
          `${res.data.user_name} (${res.data.user_id}) is successfully connected to ${res.data.player_name} (${res.data.player_id})`
        );
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
        console.log(err.request);
        err.response.data.detail
          ? alert(err.response.data.detail)
          : alert("Failed to accept the request. Please talk to DEV team");
      });
  };
};

export const rejectConnectionRequest = (requestId) => {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/admin/requests/${requestId}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
        },
      })
      .then((res) => {
        alert(
          `Connection request from USER ${res.data.user_name} (${res.data.user_id}) to PLAYER ${res.data.player_name} (${res.data.player_id}) is successfully rejected`
        );
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
        err.response.data.detail
          ? alert(err.response.data.detail)
          : alert("Failed to reject the request. Please talk to DEV team");
      });
  };
};

export const getUserByUsername = (username, signal) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/users/find/Username?search_value=${username}`,
        {
          signal: signal,
        }
      )
      .then((res) => {
        dispatch({ type: GET_USER_BY_USERNAME, data: res.data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

export const getUserBySearchType = (searchType, searchValue, signal) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/users/find/${searchType}?search_value=${searchValue}`,
        { signal: signal }
      )
      .then((res) => {
        dispatch({ type: GET_USER_BY_USER_TYPE, data: res.data });
      })
      .catch((err) => {
        dispatch({ type: GET_USER_BY_USER_TYPE, data: [] });
        console.log(err);
      });
  };
};
