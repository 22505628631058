import { LOGIN, VALIDATE_TOKEN, VALIDATE_TOKEN_FAIL } from "../actions/login";
const initialState = {
  isLoggedIn: null,
  validateAdmin: false,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case VALIDATE_TOKEN:
      return {
        ...state,
        isLoggedIn: true,
        validateAdmin: true,
      };
    case VALIDATE_TOKEN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        validateAdmin: false,
      };
    default:
      return state;
  }
}
