import axios from "axios";

export const createLeague = (name, priority, abv, category) => {
  return () => {
    const requestBody = {
      name: name,
      priority: priority,
      abv: abv,
      category: category,
    };

    axios
      .post(`${process.env.REACT_APP_API_PATH}/leagues`, requestBody, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("League Created!");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        alert("League Creation FAILED! Please check with DEV team.");
        window.location.reload(false);
      });
  };
};

export const createSeason = (name, startDate, endDate, leagueId) => {
  return () => {
    const requestBody = {
      name: name,
      start_date: startDate,
      end_date: endDate,
      league_id: leagueId,
    };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/seasons`, requestBody, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("Season Created!");
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Season Creation FAILED! Please check with DEV team.");
        window.location.reload(false);
      });
  };
};

export const uploadSeasonRoster = (seasonId, file) => {
  return () => {
    const requestBody = new FormData();
    requestBody.append("file", file);

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/seasons/${seasonId}/roster`,
        requestBody,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
          },
        }
      )
      .then(() => {
        alert("Season Roster Uploaded!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert(
          err.response.data.detail ??
            "Season Roster Upload FAILED! Please check with DEV team."
        );
        window.location.reload(false);
      });
  };
};

export const uploadSeasonRosterWithHashName = (seasonId, file) => {
  return () => {
    const requestBody = new FormData();
    requestBody.append("file", file);

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/seasons/${seasonId}/roster/hashname`,
        requestBody,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
          },
        }
      )
      .then(() => {
        alert("Season Roster Uploaded!");
        window.location.reload(false);
      })
      .catch((err) => {
        alert(
          err.response.data.detail ??
            "Season Roster Upload FAILED! Please check with DEV team."
        );
        window.location.reload(false);
      });
  };
};
