import React, { useEffect, useState } from "react";
import { leagueCategory } from "../consts/leagueCategory";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { getLeagues } from "../store/actions/teamSeason";
import LeagueDetail from "./LeagueDetail";
import "./LeagueSearch.scss";

const LeagueTableHeader = () => {
  return (
    <thead>
      <tr>
        <th>NAME</th>
        <th>ABBREVIATION</th>
        <th>PRIORITY (-1 To Hide)</th>
        <th>CATEGORY</th>
      </tr>
    </thead>
  );
};

const LeagueSearch = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("All");
  const [league, setLeague] = useState("");
  const { leagues } = useSelector((store) => store.enrollSeasonReducer);

  useEffect(() => {
    if (category) {
      const parameter = `&league_category=${category}`;
      dispatch(getLeagues(category === "All" ? "" : parameter));
    }
  }, [category]);

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setLeague("");
  };

  const handleLeagueIdChange = (event) => {
    setLeague(event.target.value);
  };

  return (
    <div className="league-search-wrapper">
      <h2>Search League</h2>
      <div className="input-wrapper">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="category-selector-label">Category</InputLabel>
          <Select
            labelId="league-selector-label"
            id="league-selector"
            value={category}
            label="Category"
            onChange={handleCategoryChange}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            <MenuItem value={"All"} key={99}>
              All
            </MenuItem>
            {leagueCategory.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="league-selector-label">Select League</InputLabel>
          <Select
            labelId="league-selector-label"
            id="league-selector"
            value={league}
            label="Select League"
            onChange={handleLeagueIdChange}
          >
            {leagues.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      {league && (
        <table>
          <LeagueTableHeader />
          <tbody>
            <LeagueDetail league={league} />
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LeagueSearch;
