import {
  GET_MATCH_DETAIL,
  GET_MATCH_DETAIL_FAIL,
  GET_MATCH_IMAGES,
  GET_MATCH_IMAGES_FAIL,
  UPDATE_VIDEO,
  UPDATE_MVP,
} from "../actions/matchDetail";
const initialState = {
  matchDetail: null,
  winPlayers: [],
};

export default function matchDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MATCH_DETAIL:
      return {
        ...state,
        matchDetail: action.data,
        winPlayers: action.winPlayers,
      };
    case GET_MATCH_DETAIL_FAIL:
      return {
        ...state,
        matchDetail: null,
      };
    case UPDATE_VIDEO:
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
          match_videos: action.data,
        },
      };
    case UPDATE_MVP:
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
          mvp: {
            ...state.matchDetail.mvp,
            mvp_photo: action.data,
          },
        },
      };
    case GET_MATCH_IMAGES:
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
        },
        matchImages: action.data,
      };
    case GET_MATCH_IMAGES_FAIL:
      return {
        ...state,
        matchDetail: {
          ...state.matchDetail,
        },
        matchImages: null,
      };
    default:
      return state;
  }
}
