import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeamCaptains,
  deleteTeamCaptain,
  getTeamCaptains,
} from "../store/actions/teamDetail";
import { Autocomplete, Button, TextField } from "@mui/material";
import { getPlayersByPlayerName } from "../store/actions/player";
import "./TeamCaptains.scss";

const TeamCaptains = () => {
  const dispatch = useDispatch();
  const { teamDetail, teamCaptains } = useSelector(
    (store) => store.teamDetailReducer
  );
  const { playersWithTeams } = useSelector((store) => store.playerReducer);
  const [newCaptains, setNewCaptains] = useState([]);
  const prevController = useRef();

  useEffect(() => {
    dispatch(getTeamCaptains(teamDetail?.id));
  }, [teamDetail]);

  const handleRemoveCaptain = (e) => {
    dispatch(deleteTeamCaptain(e.target.value));
  };

  const handleSearchSubmit = (event, value) => {
    if (prevController.current) {
      prevController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;
    prevController.current = controller;

    console.log(event);

    dispatch(getPlayersByPlayerName(value, signal));
  };

  const handleAutoCompleteChange = (event, value) => {
    console.log(event);
    setNewCaptains([...value.map((player) => player.id)]);
  };

  const confirmAddCaptains = () => {
    dispatch(addTeamCaptains(teamDetail?.id, newCaptains));
  };

  return (
    <div className="team-detail-captains-container">
      <h3>{teamDetail?.name} Captains</h3>
      <div className="team-detail-captains">
        <div className="team-captains-list">
          <div className="team-captains-list-title">
            <h4>Name</h4>
            <h4>Username</h4>
          </div>
          {teamCaptains?.map((captain) => {
            return (
              <div className="team-captain-item" key={captain.id}>
                <span>{captain.player_name}</span>
                <span>{captain.username}</span>
                <div className="remove-button-wrapper">
                  <Button
                    variant="outlined"
                    color="error"
                    value={captain.id}
                    onClick={handleRemoveCaptain}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="team-captains-add">
          <Autocomplete
            multiple
            id="player-search"
            options={playersWithTeams}
            onInputChange={handleSearchSubmit}
            getOptionLabel={(option) =>
              option.name + " - " + option.teams.map((team) => team.name)
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Search Player" />
            )}
            onChange={handleAutoCompleteChange}
          />
          <Button
            disabled={newCaptains.length === 0}
            variant="outlined"
            onClick={confirmAddCaptains}
            sx={{ mb: "1rem" }}
          >
            Add Captains
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TeamCaptains;
