import React, { useState, useRef, useMemo, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import { useDispatch } from "react-redux";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "./Editor.scss";
import * as Emoji from "quill-emoji";
import { uploadImage } from "../store/actions/postDetail";

Quill.register("modules/emoji", Emoji);

const Editor = (props) => {
  const quillRef = useRef();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  useEffect(() => {
    AddImageOnEditor(props.insertedImage);
    props.onInsertImage(""); // Initialize for next insertion
  }, [props.insertedImage]);

  useEffect(() => {
    setOldContent(props.content);
  }, []);

  /** Convert old content in html and put it on Quill Text Editor when post is being edited */
  const setOldContent = (oldContent) => {
    const delta = quillRef.current.getEditor().clipboard.convert(oldContent);
    quillRef.current.getEditor().setContents(delta, "silent");
  };

  const handleChange = async (content) => {
    setValue(content);
    if (props.onChange) await props.onChange(content); // Update the content on the parent component
  };

  const AddImageOnEditor = async (url) => {
    if (!url) return; // Avoid adding empty image tag
    const range = quillRef.current?.getEditor().getSelection()?.index;
    if (range !== null && range !== undefined) {
      let quill = quillRef.current?.getEditor();

      quill?.setSelection(range, 1);
      quill?.insertEmbed(range, "image", url);
      quill?.setSelection(range + 1, 1);
    }
  };

  const imageHandler = () => {
    const input = document.createElement("input");
    let url = "";

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files;
      file
        ? await dispatch(uploadImage(file[0])).then((res) => {
            // Upload the image on s3 first
            url = res;
          })
        : null;
      await props.onAddImage(url); // Update the image list on the parent component (PostWrite)

      await AddImageOnEditor(url);
    };
  };

  const modules = useMemo(
    //useMemo to avoid losing focus
    () => ({
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }, { background: [] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          ["emoji"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      "emoji-toolbar": true,
    }),
    []
  );

  const formats = [
    "size",
    "header",
    "bold",
    "italic",
    "underline",
    "blockquote",
    "color",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "emoji",
  ];

  return (
    <ReactQuill
      className="quill-editor-wrapper"
      theme="snow"
      ref={quillRef}
      modules={modules}
      formats={formats}
      value={value}
      preserveWhitespace={true}
      onChange={handleChange}
    />
  );
};

export default Editor;
