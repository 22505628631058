import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./TeamList.scss";
import { getTeams } from "../store/actions/team";
import { useSearchParams } from "react-router-dom";
import TeamListItem from "../component/TeamListItem";

const TeamList = () => {
  const dispatch = useDispatch();
  const { teams } = useSelector((store) => store.teamReducer);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getTeams());
  }, [searchParams]);

  return (
    <div className="team-page-wrapper">
      <div className="team-list-wrapper">
        {!teams.length ? (
          <div>no contents</div>
        ) : (
          teams.map((team) => {
            return <TeamListItem key={team.name} team={team} />;
          })
        )}
      </div>
    </div>
  );
};

export default TeamList;
