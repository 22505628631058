import axios from "axios";

export const GET_MATCHES = "GET_MATCHES";
export const GET_MATCHES_SUCCESS = "GET_MATCHES_SUCCESS";
export const GET_MATCHES_FAIL = "GET_MATCHES_FAIL";

export function getMatches(pageNum) {
  return (dispatch) => {
    dispatch({ type: GET_MATCHES });
    axios
      .get(`${process.env.REACT_APP_API_PATH}/schedules/pages?num=${pageNum}`)
      .then((res) => {
        dispatch({
          type: GET_MATCHES_SUCCESS,
          data: res.data.match_schedules_list,
          hasNext: res.data.has_next,
          hasPrev: res.data.has_prev,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
        dispatch({ type: GET_MATCHES_FAIL });
      });
  };
}
