import React from "react";
import { useNavigate } from "react-router-dom";
import "./PostListItem.scss";

const PostListItem = ({ post }) => {
  const navigate = useNavigate();
  const today = new Date();
  const todayLocale = new Date(
    today.getTime() - today.getTimezoneOffset() * 60000
  )
    .toISOString()
    .slice(0, 10)
    .replaceAll("-", ".");

  return (
    <div
      className="post-item-container"
      onClick={() => {
        navigate(`${post.id}`);
      }}
    >
      <span className="post-item post-league-datetime">
        <span>{post.league_abv ?? "All"}</span>
        {post.created_at.date === todayLocale
          ? post.created_at.time
          : post.created_at.date}
      </span>
      <div className="post-item post-thumbnail-title-wrapper">
        {post.thumbnail_url ? (
          <img className="post-thumbnail" src={post.thumbnail_url} />
        ) : null}
        <span className="post-item post-title">{post.title}</span>
      </div>
      <span className="post-item post-author">{post.username}</span>
    </div>
  );
};

export default PostListItem;
