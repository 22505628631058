import {
  GET_LEAGUES,
  GET_PLAYERS,
  GET_PLAYER,
  GET_SEASONS,
  GET_TEAMS,
  GET_PLAYERS_BY_PLAYER_NAME,
} from "../actions/player";
const initialState = {
  leagues: [],
  seasons: [],
  teams: [],
  players: [],
  player: [],
  playersWithTeams: [],
};

export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEAGUES:
      return {
        ...state,
        leagues: action.data,
      };
    case GET_SEASONS:
      return {
        ...state,
        seasons: action.data,
      };
    case GET_TEAMS:
      return {
        ...state,
        teams: action.data,
      };
    case GET_PLAYERS:
      return {
        ...state,
        players: action.data,
      };
    case GET_PLAYER:
      return {
        ...state,
        player: action.data,
      };
    case GET_PLAYERS_BY_PLAYER_NAME:
      return {
        ...state,
        playersWithTeams: action.data,
      };
    default:
      return state;
  }
}
