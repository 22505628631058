import React, { useState } from "react";
import {
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import { createSeason } from "../store/actions/seasons";
import "./SeasonCreate.scss";

const SeasonCreate = () => {
  const dispatch = useDispatch();
  const { leagues } = useSelector((store) => store.enrollSeasonReducer);
  const [leagueId, setLeagueId] = useState("");
  const [seasonName, setSeasonName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const convertDateFormat = (date) => {
    let copiedDate = new Date(date.getTime());
    return copiedDate.toISOString().split("T")[0];
  };

  const handleCreateSeason = () => {
    const startDateISO = convertDateFormat(startDate);
    const endDateISO = convertDateFormat(endDate);
    dispatch(createSeason(seasonName, startDateISO, endDateISO, leagueId));
  };

  return (
    <div className="season-create-wrapper">
      <h2>Create New Season</h2>
      <div className="input-wrapper">
        <FormControl sx={{ m: 1 }}>
          <InputLabel id="league-selector-label">League</InputLabel>
          <Select
            labelId="league-selector-label"
            id="league-selector"
            value={leagueId}
            label="League"
            onChange={(event) => {
              setLeagueId(event.target.value);
            }}
            sx={{ color: "#fff", borderColor: "#fff" }}
          >
            {leagues?.map((item, index) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.abv}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          id="season-name-text-field"
          label="Season Name"
          variant="outlined"
          sx={{ margin: "0.5rem" }}
          required={true}
          value={seasonName}
          onChange={(event) => {
            setSeasonName(event.target.value);
          }}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
          <DatePicker
            renderInput={(props) => (
              <TextField id="start-date-text-field" {...props} />
            )}
            inputFormat="yyyy-MM-dd"
            views={["year", "month", "day"]}
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
          <DatePicker
            renderInput={(props) => (
              <TextField id="end-date-text-field" {...props} />
            )}
            inputFormat="yyyy-MM-dd"
            views={["year", "month", "day"]}
            label="End Date"
            value={endDate}
            onChange={setEndDate}
          />
        </LocalizationProvider>
      </div>
      <Button
        disabled={leagueId === "" || seasonName === ""}
        variant="outlined"
        color="primary"
        onClick={handleCreateSeason}
        sx={{ margin: "0.5rem" }}
      >
        Create Season
      </Button>
    </div>
  );
};

export default SeasonCreate;
