export const sampleRoster = [
  { num: 1, name: "Bobby Lee" },
  { num: 2, name: "Jongha Kim" },
  { num: 3, name: "Taemin Ha" },
  { num: 4, name: "Michael Jordan" },
  { num: 5, name: "Kihwan Kim" },
];

export const sampleSubs = [
  { num: 11, name: "Tommy Lee" },
  { num: 12, name: "John Kim" },
  { num: 13, name: "Jim Ha" },
  { num: 14, name: "Alex" },
  { num: 25, name: "Peter Parker" },
];
