import axios from "axios";

export const GET_LEAGUES = "GET_LEAGUES";
export const GET_SEASONS = "GET_SEASONS";
export const GET_TEAMS = "GET_TEAMS";
export const GET_PLAYERS = "GET_PLAYERS";
export const GET_PLAYER = "GET_PLAYER";
export const GET_PLAYERS_BY_PLAYER_NAME = "GET_PLAYERS_BY_PLAYER_NAME";

export function getLeagues() {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/leagues?sort_by_name=true`)
      .then((res) => {
        dispatch({ type: GET_LEAGUES, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getSeasons(leagueId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/seasons?league_id=${leagueId}`)
      .then((res) => {
        dispatch({ type: GET_SEASONS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getTeams(seasonId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/seasons/${seasonId}/teams`)
      .then((res) => {
        dispatch({ type: GET_TEAMS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getPlayers(teamId, seasonId) {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/teams/${teamId}/seasons/${seasonId}/players`
      )
      .then((res) => {
        dispatch({ type: GET_PLAYERS, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function getPlayer(playerId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/players/${playerId}`)
      .then((res) => {
        dispatch({ type: GET_PLAYER, data: res.data });
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
      });
  };
}

export function createPlayer(playerName) {
  return () => {
    const request_body = {
      player_name: playerName,
    };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/player/`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("Player Created!");
        window.location.reload();
      })
      .catch((err) => {
        // TODO. toast some messages
        alert(err.response?.data.detail);
        console.log(err.response);
      });
  };
}

export function modifyPlayer(player, name, weight, height, quote) {
  return (dispatch) => {
    const request_body = {
      id: player.id,
      name: name,
      weight: weight,
      height: height,
      quote: quote,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_PATH}/players/${player.id}`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then((res) => {
        dispatch({ type: GET_PLAYER, data: res.data });
        alert("Player Modified!");
      })
      .catch((err) => {
        // TODO. toast some messages
        alert(err.response?.data.detail);
        console.log(err.response);
      });
  };
}

export function uploadPlayerPhoto(player_id, profile_photo) {
  return () => {
    const request_body = new FormData();
    request_body.append("player_id", player_id);
    request_body.append("file", profile_photo);

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/players/profile/photo`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("Profile photo is uploaded!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Update FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export function deletePlayerPhoto(player_id) {
  return () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_PATH}/players/${player_id}/profile/photo`,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("Profile photo is deleted!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Deletion FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export const getPlayersByPlayerName = (searchQuery, signal) => {
  if (searchQuery === "") {
    //clear
    return (dispatch) => {
      dispatch({ type: GET_PLAYERS_BY_PLAYER_NAME, data: [] });
    };
  }

  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_PATH}/players/search?player_name=${searchQuery}&is_hash_name=true`,
        { signal: signal }
      )
      .then((res) => {
        dispatch({ type: GET_PLAYERS_BY_PLAYER_NAME, data: res.data });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
};

export function MoveSeasonPlayer(playerId, seasonId, hashName) {
  return () => {
    const request_body = {
      hash_name: hashName,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_PATH}/players/${playerId}/seasons/${seasonId}`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert(`SeasonPlayer moved to ${hashName}!`);
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Update FAILED! " + err.response?.data.detail);
        console.log(err.response);
      });
  };
}

export const updateBackNumber = (seasonplayerId, backNumber) => {
  return async () => {
    const requestBody = {
      back_number: backNumber,
    };

    return axios
      .patch(
        `${process.env.REACT_APP_API_PATH}/seasonplayers/${seasonplayerId}`,
        requestBody,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then((res) => {
        alert(`Back number of ${res.data.name} is updated successfully!`);
      })
      .catch((err) => {
        alert("Update FAILED! " + err.response?.data.detail);
        console.log(err);
      });
  };
};

export const excludePlayerFromSeason = (seasonplayerId) => {
  return async () => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_PATH}/seasonplayers/${seasonplayerId}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
          },
        }
      )
      .then((res) => {
        alert(
          `${res.data.name} is successfully excluded from the season roster`
        );
      })
      .catch((err) => {
        alert(err.response?.data.detail);
        console.log(err);
      });
  };
};
