import React from "react";

const MatchRoster = ({ players }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Player</th>
          <th>Jersey Number</th>
        </tr>
      </thead>
      <tbody>
        {players?.map((player, index) => {
          if (player.name !== "Totals" && player.name !== "ETC")
            return (
              <tr key={index}>
                <td>{player.name}</td>
                <td>
                  {player.back_number
                    ? player.back_number === 100
                      ? "00"
                      : player.back_number
                    : "-"}
                </td>
              </tr>
            );
        })}
      </tbody>
    </table>
  );
};

export default MatchRoster;
