import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateMatchStat } from "../store/actions/matchDetail";

const MatchStatUpdateRow = ({ stat, match_id }) => {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);
  const [min, setMin] = useState(0);
  const [points, setPoinits] = useState(0);
  const [ast, setAst] = useState(0);
  const [dreb, setDreb] = useState(0);
  const [oreb, setOreb] = useState(0);
  const [reb, setReb] = useState(0);
  const [fga, setFga] = useState(0);
  const [fgm, setFgm] = useState(0);
  const [tpa, setTpa] = useState(0);
  const [tpm, setTpm] = useState(0);
  const [fta, setFta] = useState(0);
  const [ftm, setFtm] = useState(0);
  const [stl, setStl] = useState(0);
  const [blk, setBlk] = useState(0);
  const [pf, setPf] = useState(0);
  const [to, setTo] = useState(0);

  useEffect(() => {
    setMin(stat.minutes);
    setPoinits(stat.points);
    setAst(stat.assists);
    setDreb(stat.def_rebounds);
    setOreb(stat.off_rebounds);
    setReb(stat.rebounds);
    setFga(stat.field_goal_try);
    setFgm(stat.field_goal_made);
    setTpa(stat.three_point_try);
    setTpm(stat.three_point_made);
    setFta(stat.free_throw_try);
    setFtm(stat.free_throw_made);
    setStl(stat.steals);
    setBlk(stat.blocks);
    setPf(stat.fouls);
    setTo(stat.turnovers);
  }, [stat]);
  return (
    <tr>
      <th>{stat.name}</th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="MIN"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={min}
          disabled={disabled}
          onChange={(event) => {
            setMin(event.target.value);
          }}
          type="number"
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="PTS"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={points}
          disabled={disabled}
          onChange={(event) => {
            setPoinits(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="AST"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={ast}
          disabled={disabled}
          onChange={(event) => {
            setAst(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="DREB"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={dreb}
          disabled={disabled}
          onChange={(event) => {
            setDreb(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="OREB"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={oreb}
          disabled={disabled}
          onChange={(event) => {
            setOreb(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="REB"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={reb}
          disabled={disabled}
          onChange={(event) => {
            setReb(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="FGA"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={fga}
          disabled={disabled}
          onChange={(event) => {
            setFga(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="FMG"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={fgm}
          disabled={disabled}
          onChange={(event) => {
            setFgm(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="3PA"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={tpa}
          disabled={disabled}
          onChange={(event) => {
            setTpa(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="3PM"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={tpm}
          disabled={disabled}
          onChange={(event) => {
            setTpm(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="FTA"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={fta}
          disabled={disabled}
          onChange={(event) => {
            setFta(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="FTM"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={ftm}
          disabled={disabled}
          onChange={(event) => {
            setFtm(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="STL"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={stl}
          disabled={disabled}
          onChange={(event) => {
            setStl(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="BLK"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={blk}
          disabled={disabled}
          onChange={(event) => {
            setBlk(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="PF"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={pf}
          disabled={disabled}
          onChange={(event) => {
            setPf(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="match-detail-stat-text-field"
          label="TO"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={to}
          disabled={disabled}
          onChange={(event) => {
            setTo(event.target.value);
          }}
        />
      </th>
      <th>
        <Button
          variant="outlined"
          onClick={() => {
            setDisabled(!disabled);

            if (
              min != stat.minutes ||
              points != stat.points ||
              ast != stat.assists ||
              dreb != stat.def_rebounds ||
              oreb != stat.off_rebounds ||
              reb != stat.rebounds ||
              fga != stat.field_goal_try ||
              fgm != stat.field_goal_made ||
              tpa != stat.three_point_try ||
              tpm != stat.three_point_made ||
              fta != stat.free_throw_try ||
              ftm != stat.free_throw_made ||
              stl != stat.steals ||
              blk != stat.blocks ||
              pf != stat.fouls ||
              to != stat.turnovers
            ) {
              dispatch(
                updateMatchStat(
                  match_id,
                  stat.id,
                  min,
                  points,
                  ast,
                  dreb,
                  oreb,
                  reb,
                  fga,
                  fgm,
                  tpa,
                  tpm,
                  fta,
                  ftm,
                  stl,
                  blk,
                  pf,
                  to
                )
              );
            }
          }}
        >
          {disabled ? "modify" : "update"}
        </Button>
      </th>
    </tr>
  );
};

export default MatchStatUpdateRow;
