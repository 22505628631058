import axios from "axios";

export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAIL = "GET_POSTS_FAIL";

export function getPosts(currentPage, leagueId, searchQuery) {
  return (dispatch) => {
    dispatch({ type: GET_POSTS });
    axios
      .get(
        `${
          process.env.REACT_APP_API_PATH
        }/posts/page?current_page=${currentPage}${
          leagueId !== "Show All" ? "&league_id=" + leagueId : ""
        }${
          searchQuery !== undefined && searchQuery !== ""
            ? "&search_query=" + searchQuery
            : ""
        }&hidden=true`
      )
      .then((res) => {
        dispatch({
          type: GET_POSTS_SUCCESS,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_POSTS_FAIL });
      });
  };
}

export function createPost(
  leagueAbv,
  title,
  content,
  status,
  imageUrlList,
  thumbnailUrl
) {
  if (status === undefined) {
    status = "Default";
  }
  return () => {
    const requestBody = new FormData();
    requestBody.append("league_abv", leagueAbv);
    requestBody.append("title", title);
    requestBody.append("status", status);
    if (imageUrlList.length) {
      requestBody.append("image_urls", imageUrlList);
    }
    requestBody.append("content", content);
    if (thumbnailUrl !== "") {
      requestBody.append("thumbnail_url", thumbnailUrl);
    }

    axios
      .post(`${process.env.REACT_APP_API_PATH}/posts`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        window.location.href = "/posts";
      })
      .catch((err) => {
        console.log(err);
        alert("Error: " + err.response.data.message);
      });
  };
}

export function editPost(
  postId,
  leagueAbv,
  title,
  content,
  status,
  imageUrlList,
  thumbnailUrl
) {
  if (status === undefined) {
    status = "Default";
  }
  return () => {
    const requestBody = new FormData();
    requestBody.append("league_abv", leagueAbv);
    requestBody.append("title", title);
    requestBody.append("status", status);
    if (imageUrlList.length) {
      requestBody.append("image_urls", imageUrlList);
    }
    requestBody.append("content", content);
    if (thumbnailUrl !== "") {
      requestBody.append("thumbnail_url", thumbnailUrl);
    }

    axios
      .put(`${process.env.REACT_APP_API_PATH}/posts/${postId}`, requestBody, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("sketch-token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        window.location.href = "/posts";
      })
      .catch((err) => {
        console.log(err);
        alert("Error: " + err.response.data.message);
      });
  };
}
