import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { leagueCategory } from "../consts/leagueCategory";
import React, { useState } from "react";
import "./LeagueCreate.scss";
import { useDispatch } from "react-redux";
import { createLeague } from "../store/actions/seasons";

const LeagueCreate = () => {
  const dispatch = useDispatch();
  const [leagueName, setLeagueName] = useState("");
  const [leagueAbv, setLeagueAbv] = useState("");
  const [priority, setPriority] = useState(null);
  const [category, setCategory] = useState(null);

  const handleLeagueNameChange = (e) => {
    setLeagueName(e.target.value);
  };

  const handleLeagueAbvChange = (e) => {
    setLeagueAbv(e.target.value);
  };

  const handlePriorityChange = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
      return;
    }
    setPriority(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleCreateLeague = () => {
    dispatch(createLeague(leagueName, priority, leagueAbv, category));
  };

  return (
    <div className="league-create-wrapper">
      <h2>Create New League</h2>
      <div className="input-wrapper">
        <TextField
          id="league-name-text-field"
          label="League Name"
          variant="outlined"
          sx={{ margin: "0.5rem" }}
          required={true}
          value={leagueName}
          onChange={handleLeagueNameChange}
        />

        <TextField
          id="league-abv-text-field"
          label="Abbreviation"
          variant="outlined"
          sx={{ margin: "0.5rem" }}
          required={true}
          value={leagueAbv}
          placeholder="Sponsor + Division (EX. Joint Dynamics D1)"
          onChange={handleLeagueAbvChange}
        />

        <TextField
          id="priority-text-field"
          label="Priority"
          variant="outlined"
          type="number"
          sx={{ margin: "0.5rem" }}
          required={true}
          value={priority || ""}
          placeholder="High Priority 0 ~ 99 Low Priority"
          onChange={handlePriorityChange}
        />

        <FormControl sx={{ m: 1 }}>
          <InputLabel id="league-category-selector-label">Category</InputLabel>
          <Select
            labelId="league-category-selector-label"
            id="league-category-selector"
            label="Category"
            value={category || ""}
            onChange={handleCategoryChange}
          >
            {leagueCategory.map((item, index) => {
              return (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <Button
        disabled={!(leagueName && leagueAbv && priority && category)}
        variant="outlined"
        color="primary"
        onClick={handleCreateLeague}
        sx={{ margin: "0.5rem" }}
      >
        Create League
      </Button>
    </div>
  );
};

export default LeagueCreate;
