import { GET_POSTS, GET_POSTS_SUCCESS, GET_POSTS_FAIL } from "../actions/post";
const initialState = {
  startPage: 0,
  endPage: 0,
  isLast: false,
  posts: [],
  postsLoading: false,
};

export default function postReducer(state = initialState, action) {
  switch (action.type) {
    case GET_POSTS:
      return {
        ...state,
        postsLoading: true,
      };
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        startPage: action.data.start_page,
        endPage: action.data.end_page,
        isLast: action.data.is_last,
        posts: action.data.posts,
        postsLoading: false,
      };
    case GET_POSTS_FAIL:
      return {
        ...initialState,
        postsLoading: false,
      };
    default:
      return state;
  }
}
