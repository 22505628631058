import matchCreateReducer from "./matchCreate";
import matchReducer from "./match";
import playerReducer from "./player";
import matchDetailReducer from "./matchDetail";
import teamReducer from "./team";
import teamDetailReducer from "./teamDetail";
import enrollSeasonReducer from "./teamSeason";
import { combineReducers } from "redux";
import loginReducer from "./login";
import postReducer from "./post";
import postDetailReducer from "./postDetail";
import userReducer from "./user";

const rootReducer = combineReducers({
  enrollSeasonReducer,
  matchCreateReducer,
  matchReducer,
  matchDetailReducer,
  loginReducer,
  teamReducer,
  postReducer,
  postDetailReducer,
  teamDetailReducer,
  playerReducer,
  userReducer,
});

export default rootReducer;
