import {
  GET_TEAM_DETAIL,
  GET_TEAM_DETAIL_FAIL,
  GET_TEAM_CAPTAINS_SUCCESS,
  GET_TEAM_CAPTAINS_FAIL,
  GET_TEAM_COACHES_SUCCESS,
  GET_TEAM_COACHES_FAIL,
  GET_ENROLLED_SEASONS,
  GET_SEASON_ROSTER,
} from "../actions/teamDetail";
const initialState = {
  teamDetail: null,
  teamCaptains: null,
  teamCoaches: null,
};

export default function teamDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_DETAIL:
      return {
        ...state,
        teamDetail: action.data,
        // teamSeasons: action.data,
      };
    case GET_TEAM_DETAIL_FAIL:
      return {
        ...state,
        teamDetail: null,
      };
    case GET_TEAM_CAPTAINS_SUCCESS:
      return {
        ...state,
        teamCaptains: action.data,
      };
    case GET_TEAM_CAPTAINS_FAIL:
      return {
        ...state,
        teamCaptains: null,
      };
    case GET_TEAM_COACHES_SUCCESS:
      return {
        ...state,
        teamCoaches: action.data,
      };
    case GET_TEAM_COACHES_FAIL:
      return {
        ...state,
        teamCoaches: null,
      };
    case GET_ENROLLED_SEASONS:
      return {
        ...state,
        enrolledSeasons: action.data,
      };
    case GET_SEASON_ROSTER:
      return {
        ...state,
        seasonRoster: action.data,
      };
    default:
      return state;
  }
}
