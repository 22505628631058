import axios from "axios";

export const GET_MATCH_DETAIL = "GET_MATCH_DETAIL";
export const GET_MATCH_DETAIL_FAIL = "GET_MATCH_DETAIL_FAIL";
export const GET_MATCH_IMAGES = "GET_MATCH_IMAGES";
export const GET_MATCH_IMAGES_FAIL = "GET_MATCH_IMAGES_FAIL";
export const UPLOAD_STATS = "UPLOAD_STATS";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const UPDATE_MVP = "UPDATE_MVP";
export const UPDATE_SHOOTING_CHART = "UPDATE_SHOOTING_CHART";
export const UPDATE_MATCH_NAME = "UPDATE_MATCH_NAME";
export const UPDATE_MATCH_LOCATION = "UPDATE_MATCH_LOCATION";
export const UPLOAD_MATCH_IMAGES = "UPLOAD_MATCH_IMAGES";

export function getMatchDetail(matchId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/matches/${matchId}`)
      .then((res) => {
        if (res.data.home_win) {
          dispatch({
            type: GET_MATCH_DETAIL,
            data: res.data,
            winPlayers: res.data.home_team.match_stats,
          });
        } else {
          dispatch({
            type: GET_MATCH_DETAIL,
            data: res.data,
            winPlayers: res.data.away_team.match_stats,
          });
        }
      })
      .catch((err) => {
        // TODO. toast some messages
        console.log(err);
        dispatch({ type: GET_MATCH_DETAIL_FAIL });
      });
  };
}

export function deleteMatch(matchId) {
  return () => {
    axios
      .delete(`${process.env.REACT_APP_API_PATH}/matches/${matchId}`, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then((res) => {
        alert("Match has been successfully deleted! " + res.data.detail);
        // redirect to admin /matches
        window.location.href = "/matches";
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Match Deletion Failed. Contact DEV Team!");
        console.log(err);
      });
  };
}

export function forfeit(matchId, forfeitType) {
  return (dispatch) => {
    const request_body = {
      match_id: matchId,
      forfeit: forfeitType,
    };
    axios
      .post(`${process.env.REACT_APP_API_PATH}/matches/forfeit`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        axios
          .get(`${process.env.REACT_APP_API_PATH}/matches/${matchId}`)
          .then((res) => {
            if (res.data.home_win) {
              dispatch({
                type: GET_MATCH_DETAIL,
                data: res.data,
                winPlayers: res.data.home_team.match_stats,
              });
            } else {
              dispatch({
                type: GET_MATCH_DETAIL,
                data: res.data,
                winPlayers: res.data.away_team.match_stats,
              });
            }
          });
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Forfeit FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export function uploadStats(matchId, statsFile) {
  return () => {
    const request_body = new FormData();
    request_body.append("match_id", matchId);
    request_body.append("file", statsFile);

    console.log(request_body);

    axios
      .post(`${process.env.REACT_APP_API_PATH}/matches/stats`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("File is Successfully uploaded!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
}

export function uploadStats_V2(matchId, statsFile) {
  return () => {
    const request_body = new FormData();
    request_body.append("match_id", matchId);
    request_body.append("file", statsFile);

    console.log(request_body);

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/matches/stats/v2`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("File is Successfully uploaded!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("ERROR: " + err.response.data.detail);
        console.log(err);
      });
  };
}

export function updateMatchVideo(matchId, videoId) {
  return () => {
    const request_body = new FormData();
    request_body.append("match_id", matchId);
    request_body.append("video_url", videoId);

    axios
      .post(`${process.env.REACT_APP_API_PATH}/matches/video`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("Match Video Updated!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Update FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export function updateMatchName(matchId, matchName) {
  return () => {
    const request_body = {
      id: matchId,
      match_name: matchName,
    };

    axios
      .post(`${process.env.REACT_APP_API_PATH}/matches/name`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert("Match Name Updated!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Update FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export function updateMatchLocation(matchId, matchLocation) {
  return () => {
    const request_body = {
      id: matchId,
      location: matchLocation,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/matches/location`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("Match Location Updated!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Update FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export const updateMatchDeadline = (matchId, registrationDeadline) => {
  return () => {
    const request_body = {
      id: matchId,
      registration_deadline: registrationDeadline,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/matches/deadline`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then(() => {
        alert("Match Sign-in Deadline Updated!");
        window.location.reload(false);
      })
      .catch((err) => {
        // TODO. toast some messages
        if (err.response.status === 400) {
          alert(err.response.data.detail);
        } else {
          alert("Update FAILED! Please check with DEV team.");
        }
        console.log(err);
      });
  };
};

export function uploadMvp(matchStatId, mvpImage) {
  return (dispatch) => {
    const request_body = new FormData();
    request_body.append("matchstat_id", matchStatId);
    request_body.append("file", mvpImage);

    axios
      .post(`${process.env.REACT_APP_API_PATH}/matches/mvp`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_MVP,
          data: res.data.image_url,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Update FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export function uploadShootingChart(shootingCharts) {
  return () => {
    const request_body = new FormData();

    Promise.all(
      shootingCharts.map(async ([matchStatId, shootingChart]) => {
        request_body.set("matchstat_id", matchStatId);
        request_body.set("file", shootingChart);

        return axios
          .post(
            `${process.env.REACT_APP_API_PATH}/matches/shooting_chart`,
            request_body,
            {
              headers: {
                Authorization: "bearer " + localStorage.getItem("sketch-token"),
              },
            }
          )
          .then((res) => {
            //console.log("success " + matchStatId);
            return res;
          })
          .catch((err) => {
            alert(
              `Upload FAILED! Unsuccessful in uploading ${shootingChart.name} \nError: ${err.response.data.detail}`
            );
            throw err;
          });
      })
    )
      .then(() => {
        alert("Shooting Chart(s) uploaded!");
        window.location.reload(false);
      })
      .catch(() => {
        window.location.reload(false);
      });
  };
}

export function updateMatchStat(
  match_id,
  id,
  min,
  pts,
  ast,
  dreb,
  oreb,
  reb,
  fga,
  fgm,
  tpa,
  tpm,
  fta,
  ftm,
  stl,
  blk,
  pf,
  to
) {
  return (dispatch) => {
    const request_body = {
      id: id,
      minutes: parseInt(min),
      points: parseInt(pts),
      assists: parseInt(ast),
      def_rebound: parseInt(dreb),
      off_rebounds: parseInt(oreb),
      rebounds: parseInt(reb),
      field_goal_try: parseInt(fga),
      field_goal_made: parseInt(fgm),
      free_throw_try: parseInt(fta),
      free_throw_made: parseInt(ftm),
      three_point_try: parseInt(tpa),
      three_point_made: parseInt(tpm),
      steals: parseInt(stl),
      blocks: parseInt(blk),
      fouls: parseInt(pf),
      turnovers: parseInt(to),
    };
    axios
      .put(`${process.env.REACT_APP_API_PATH}/matchstats`, request_body, {
        headers: {
          Authorization: "bearer " + localStorage.getItem("sketch-token"),
        },
      })
      .then(() => {
        alert(`stat updated! for match ${match_id}`);
        axios
          .post(
            `${process.env.REACT_APP_API_PATH}/matches/${match_id}/sync-winner`,
            null,
            {
              headers: {
                Authorization: "bearer " + localStorage.getItem("sketch-token"),
              },
            }
          )
          .then((res) => {
            if (res.data.home_win) {
              dispatch({
                type: GET_MATCH_DETAIL,
                data: res.data,
                winPlayers: res.data.home_team.match_stats,
              });
            } else {
              dispatch({
                type: GET_MATCH_DETAIL,
                data: res.data,
                winPlayers: res.data.away_team.match_stats,
              });
            }
          })
          .catch((err) => {
            // TODO. toast some messages
            console.log(err);
            dispatch({ type: GET_MATCH_DETAIL_FAIL });
          });
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Stat Update FAILED! Please check with DEV team.");
        console.log(err);
        axios
          .get(`${process.env.REACT_APP_API_PATH}/matches/${match_id}`)
          .then((res) => {
            if (res.data.home_win) {
              dispatch({
                type: GET_MATCH_DETAIL,
                data: res.data,
                winPlayers: res.data.home_team.match_stats,
              });
            } else {
              dispatch({
                type: GET_MATCH_DETAIL,
                data: res.data,
                winPlayers: res.data.away_team.match_stats,
              });
            }
          })
          .catch((err) => {
            // TODO. toast some messages
            console.log(err);
            dispatch({ type: GET_MATCH_DETAIL_FAIL });
          });
      });
  };
}

export function sendEmailNotification(matchId) {
  return () => {
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/matches/${matchId}/notification`,
        {},
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then((res) => {
        alert("Email send request to [" + res.data.result + "]");
        console.log(res.data.recipient);
        console.log(res.data.result);
        return res;
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Send Email failed. Contact DEV Team!");
        console.log(err);
      });
  };
}

export function uploadMatchImages(matchId, matchImages) {
  return () => {
    const request_body = new FormData();
    matchImages.forEach((file) => {
      request_body.append("files", file);
    });

    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/matches/${matchId}/images`,
        request_body,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then((res) => {
        alert(
          "Uploading the images to cloud: " + JSON.stringify(res.data.data)
        );
        return res;
      })
      .catch((err) => {
        // TODO. toast some messages
        alert("Upload FAILED! Please check with DEV team.");
        console.log(err);
      });
  };
}

export function getMatchImages(matchId) {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_PATH}/matches/${matchId}/images`)
      .then((res) => {
        dispatch({
          type: GET_MATCH_IMAGES,
          data: res.data,
        });
      })
      .catch((err) => {
        // TODO. toast some messages
        dispatch({ type: GET_MATCH_IMAGES_FAIL });
        console.log(err);
      });
  };
}

export function syncMatchWinner(matchId) {
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_PATH}/matches/${matchId}/sync-winner`,
        null,
        {
          headers: {
            Authorization: "bearer " + localStorage.getItem("sketch-token"),
          },
        }
      )
      .then((res) => {
        if (res.data.home_win) {
          dispatch({
            type: GET_MATCH_DETAIL,
            data: res.data,
            winPlayers: res.data.home_team.match_stats,
          });
        } else {
          dispatch({
            type: GET_MATCH_DETAIL,
            data: res.data,
            winPlayers: res.data.away_team.match_stats,
          });
        }
      })
      .catch((err) => {
        alert("Sync winner FAILED! Please check with DEV team.");
        console.log(err);
        dispatch({ type: GET_MATCH_DETAIL_FAIL });
      });
  };
}
