import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import Button from "@mui/material/Button";
import proam from "../images/thumbnails/proam.png";
import division1 from "../images/thumbnails/division-1.png";
import division2 from "../images/thumbnails/division-2.png";
import division3 from "../images/thumbnails/division-3.png";
import division4 from "../images/thumbnails/division-4.png";
import division5 from "../images/thumbnails/division-5.png";
import division6 from "../images/thumbnails/division-6.png";
import u16 from "../images/thumbnails/u16.png";
import u14 from "../images/thumbnails/u14.png";
import u12 from "../images/thumbnails/u12.png";
import logo from "../images/thumbnails/default-team-logo.png";
import "./Thumbnail.scss";

const Thumbnail = () => {
  const [image, setImage] = useState(null);
  const [fontColor, setFontColor] = useState("FFCC99");
  const { matchDetail } = useSelector((store) => store.matchDetailReducer);

  useEffect(() => {
    if (matchDetail) {
      switch (matchDetail.league_abv) {
        case "Sketch Pro Am":
          setImage(proam);
          setFontColor("FFFF00");
          break;
        case "Joint Dynamics D1":
          setImage(division1);
          setFontColor("99FFFF");
          break;
        case "Masterwook D2":
          setImage(division2);
          setFontColor("FFFFFF");
          break;
        case "Refundz D3":
          setImage(division3);
          setFontColor("8dc3fd");
          break;
        case "Zanchee D4":
          setImage(division4);
          setFontColor("1bf9e3");
          break;
        case "Zerve D5":
          setImage(division5);
          setFontColor("FFCC33");
          break;
        case "Sketch D6":
          setImage(division6);
          break;
        case "U-16":
          setImage(u16);
          break;
        case "U-14 D1" || "U-14 D2":
          setImage(u14);
          break;
        case "U-12 D1" || "U-12 D2":
          setImage(u12);
          break;
        default:
          setImage(null);
      }
    }
  }, [matchDetail]);

  const exportThumbnail = async () => {
    const thumbnail = document.querySelector(".thumbnail");
    const canvas = await html2canvas(thumbnail);
    const image = canvas.toDataURL("image/png");
    window.saveAs(image, "thumbnail.png");
  };

  return (
    <div className="thumbnail-wrapper">
      <div className="thumbnail" style={{ backgroundImage: `url(${image})` }}>
        <div className="thumbnail-match-name-wrapper">
          {matchDetail?.match_type !== "Playoff" ? (
            <div className="thumbnail-match-name">
              {matchDetail?.match_name}
            </div>
          ) : (
            <div className="thumbnail-match-name-playoff">
              <span>{matchDetail?.match_type.toUpperCase()}</span>
              <span>{matchDetail?.match_name}</span>
            </div>
          )}
        </div>
        <div className="thumbnail-teams" style={{ color: `#${fontColor}` }}>
          <img
            className="thumbnail-home-team-logo"
            src={matchDetail?.home_team.logo ?? logo}
            alt="home-team-logo"
          />
          <span className="thumbnail-home-team-name thumbnail-team-name">
            {matchDetail?.home_team.name.toUpperCase()}
          </span>
          <img
            className="thumbnail-away-team-logo"
            src={matchDetail?.away_team.logo ?? logo}
            alt="away-team-logo"
          />
          <span className="thumbnail-away-team-name thumbnail-team-name">
            {matchDetail?.away_team.name.toUpperCase()}
          </span>
        </div>
      </div>
      <Button
        variant="outlined"
        sx={{ margin: "0.5rem" }}
        onClick={exportThumbnail}
      >
        Download
      </Button>
    </div>
  );
};

export default Thumbnail;
