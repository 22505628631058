import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { uploadMatchImages } from "../store/actions/matchDetail";

import "./MatchDetailPhotos.scss";
import { useDispatch } from "react-redux";

const MAX_FILE_SIZE = 8242880;

const MatchDetailPhotos = (props) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const existingFiles = props.matchImages;

  const removeFile = (index) => {
    setSelectedImages((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const uploadFiles = async () => {
    console.log("Set Loading...");
    setLoading(true);
    setUploadStatus("Uploading....");

    try {
      // Simulate asynchronous file upload process
      await dispatch(uploadMatchImages(props.matchId, selectedImages));

      alert("uploading images...");
      setUploadStatus("Upload successful!");
      setSelectedImages([]);
    } catch (error) {
      setUploadStatus("Upload failed!");
      // Handle error
      console.error(error);
    }

    setLoading(false);
  };

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      setSelectedImages((prevState) => [...prevState, file]);
    });
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          alert(`Error: ${err.message}`);
          return;
        }

        if (err.code === "file-invalid-type") {
          alert(`Error: ${err.message}`);
          return;
        }
      });
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
    maxSize: MAX_FILE_SIZE,
    maxFiles: 50,
    onDrop,
  });

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].size > MAX_FILE_SIZE;

  return (
    <>
      <h1>Current Photos</h1>
      {existingFiles?.length > 0 ? (
        <>
          <span>There's {existingFiles?.length} photos uploaded already </span>
          <div className="image-container">
            {existingFiles.map((image) => (
              <div key={image.id} className="image-wrapper">
                <img key={image.id} src={image.url} alt="Existing Image" />
              </div>
            ))}
          </div>
        </>
      ) : (
        <span>No photos yet</span>
      )}
      {loading ? (
        <>
          <div className="loading-screen">
            <h1>Loading...</h1>
            {console.log("print here ...")}
          </div>
        </>
      ) : (
        <>
          <div className="match-detail-players-wrapper">
            <span>{props.matchId}</span>
          </div>
          <div
            {...getRootProps()}
            className={`drag-and-drop-box ${isDragActive ? "dragging" : ""}`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag and drop image files here</p>
            )}
            {isDragActive && !isDragReject && "Drop it like it's hot!"}
            {isDragReject && "File type not accepted, sorry!"}
            {isFileTooLarge && (
              <div className="text-danger mt-2">File is too large.</div>
            )}
          </div>
          {selectedImages.length > 0 ? (
            <>
              <div className="image-container">
                {selectedImages.map((file, index) => (
                  <div key={index} className="image-wrapper">
                    <img
                      key={file.name}
                      src={URL.createObjectURL(file)}
                      alt="Dropped Image"
                    />
                    <button
                      className="delete-button"
                      onClick={() => removeFile(index)}
                    >
                      Remove X
                    </button>
                  </div>
                ))}
              </div>
              <div className="upload-button-container">
                <p>{uploadStatus}</p>

                <button className="upload-button" onClick={() => uploadFiles()}>
                  Upload Images
                </button>
                <br></br>
              </div>
            </>
          ) : (
            <p>No photos yet</p>
          )}
        </>
      )}
    </>
  );
};

export default MatchDetailPhotos;
