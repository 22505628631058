const youtubeConverter = (content) => {
  const youtubeRegs = [
    /https?:\/\/youtu.be\/([a-zA-Z0-9\-_]+)/gi,
    /https?:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9\-_]+)/gi,
  ];

  let tempContent = content; //temporary content to convert all links if multiple links exist
  for (var i in youtubeRegs) {
    for (
      let match = (content.match(youtubeRegs[i]) || []).length;
      match >= 0;
      match--
    ) {
      //count number of links
      const link = youtubeRegs[i].exec(tempContent);
      if (link === null) continue;
      const id = link[1];
      const youtubeIframe = `
        <div>
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/${id}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
          ></iframe>
        </div>
      `;
      content = content.replace(link[0], youtubeIframe); //change original content
      tempContent = tempContent.replace(link[0], ""); //remove converted link from temporary content to convert next link
    }
  }

  return content;
};

const instagramConverter = (content) => {
  const reg = /(https?:\/\/www\.)?instagram\.com(\/p\/([a-zA-Z0-9\-_]+)\/?)/gi;
  return countAndConvertLinks(reg, content, "//www.instagram.com/embed.js");
};

const twitterConverter = (content) => {
  const reg = /(https?:\/\/twitter\.com\/[a-zA-Z0-9_]+\/status\/([0-9]+))/gi;
  return countAndConvertLinks(
    reg,
    content,
    "//platform.twitter.com/widgets.js"
  );
};

const threadsConverter = (content) => {
  const reg =
    /(https:\/\/www\.threads\.net\/@[a-zA-Z0-9_-]+\/post\/[a-zA-Z0-9_-]+)/gi;
  return countAndConvertLinks(reg, content, "//www.threads.net/embed.js");
};

const addScript = (src) => {
  const script = document.createElement("script");
  script.async = true;
  script.src = src;
  document.body.appendChild(script);
};

const countAndConvertLinks = (regEx, content, src) => {
  const match = content.match(regEx) || []; //count number of ig links and if 0, return original content

  // if there is at least 1 ig link, add script tag
  if (match.length === 0) return content;
  addScript(src);

  let tempContent = content;
  for (let i = match.length; i > 0; i--) {
    const link = regEx.exec(tempContent);
    if (link === null) continue;
    const url = link[0];

    // convert link to sns embed element
    let snsEmbedElement;
    switch (src) {
      case "//www.instagram.com/embed.js": //instagram
        snsEmbedElement = `
          <blockquote
            class="instagram-media"
            data-instgrm-captioned
            data-instgrm-permalink="${url}"
            data-instgrm-version="14"
            style="width: calc(100% - 22px); max-width: 540px;"
          ></blockquote>
        `;
        break;
      case "//platform.twitter.com/widgets.js": //twitter
        snsEmbedElement = `
          <blockquote
            class="twitter-tweet"
          >
            <a href="${url}"></a>
          </blockquote>
        `;
        break;
      case "//www.threads.net/embed.js": //threads
        snsEmbedElement = `
          <blockquote
            class="text-post-media"
            data-text-post-permalink="${url}"
            style="max-width:540px; width:calc(100% - 22px);"
          ></blockquote>
        `;
        break;
    }

    content = content.replace(url, snsEmbedElement);
  }

  return content;
};

export const convertURLtoEmbed = (content) => {
  content = youtubeConverter(content);
  content = instagramConverter(content);
  content = twitterConverter(content);
  content = threadsConverter(content);
  return content;
};

export default convertURLtoEmbed;
