import React from "react";
import "./Match.scss";
import { Route, Routes } from "react-router-dom";
import MatchList from "./MatchList";
import MatchLive from "./MatchLive";
import MatchCreate from "./MatchCreate";
import MatchDetail from "./MatchDetail";

const Match = () => {
  return (
    <div className="match-wrapper">
      <Routes>
        <Route path="" element={<MatchList />} />
        <Route path="create" element={<MatchCreate />} />
        <Route path=":matchId/*" element={<MatchDetail />} />
        <Route path=":matchId/live" element={<MatchLive />} />
      </Routes>
    </div>
  );
};

export default Match;
