import React, { useState, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getPlayersByPlayerName } from "../store/actions/player";
import "./PlayerSearch.scss";

const PlayerSearch = () => {
  const dispatch = useDispatch();
  const [playerSearch, setPlayerSearch] = useState("");
  const { playersWithTeams } = useSelector((store) => store.playerReducer);
  const prevController = useRef();

  const handleSearchChange = (e) => {
    setPlayerSearch(e.target.value);
    handleSearchSubmit(e.target.value);
  };

  const handleSearchSubmit = (value) => {
    if (prevController.current) {
      prevController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;
    prevController.current = controller;

    dispatch(getPlayersByPlayerName(value, signal));
  };

  return (
    <div className="player-search-container">
      <div className="player-search-wrapper">
        <h2 style={{ marginTop: "2rem" }}>
          Search Player by Player Name or Hash Name
        </h2>
        <FormControl sx={{ m: 2 }} className="stats-search-input-form">
          <TextField
            id="stats-search-input"
            placeholder="Search Player"
            value={playerSearch}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <IconButton aria-label="stats-search-button">
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </FormControl>
        <div className="player-search-result-wrapper">
          {playersWithTeams.map((player, index) => (
            <div className="player-search-result" key={index}>
              <div className="player-search-content">
                <span>Player ID:</span>
                <span>Name:</span>
                <span>Hash Name:</span>
                <span>Teams:</span>
              </div>
              <div className="player-search-content searched-players">
                <span>{player.id}</span>
                <span>{player.name}</span>
                <span>{player.hash_name}</span>
                <span className="player-search-teams">
                  {player.teams.map((team, index) => (
                    <span key={index}>{team.name}</span>
                  ))}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlayerSearch;
