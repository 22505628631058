import React from "react";
import "./Main.scss";

const Main = () => {
  return (
    <div className="main-wrapper">
      <h1>SKETCH ADMIN</h1>
      <h3>Home page to be implemented</h3>
    </div>
  );
};

export default Main;
