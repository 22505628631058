import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./TeamDetail.scss";
import { useParams } from "react-router";
import {
  getTeamDetail,
  uploadLogo,
  getEnrolledSeasons,
  clearSeasonRoster,
  changeTeamName,
} from "../store/actions/teamDetail";
import {
  getLeagues,
  getSeasons,
  enrollSeason,
  deleteFromSeason,
} from "../store/actions/teamSeason";

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import TeamCaptains from "../component/TeamCaptains";
import TeamCoaches from "../component/TeamCoaches";
import SeasonRosterForm from "../component/SeasonRosterForm";

const TeamDetail = () => {
  const { teamId } = useParams();

  const [logoFileName, setLogoFileName] = useState("");
  const [logoFile, setLogoFile] = useState("");
  const [leagueId, setLeagueId] = useState("");
  const [seasonId, setSeasonId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();
  const { teamDetail } = useSelector((store) => store.teamDetailReducer);
  const { leagues, seasons } = useSelector(
    (store) => store.enrollSeasonReducer
  );
  useEffect(() => {
    dispatch(getTeamDetail(teamId));
    dispatch(getLeagues());
    dispatch(getEnrolledSeasons(teamId));

    return () => {
      dispatch(clearSeasonRoster());
    };
  }, []);

  const handleFileChange = (event) => {
    setLogoFileName(event.target.value);
    setLogoFile(event.target.files ? event.target.files[0] : null);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (leagues.length) {
      setLeagueId(leagues[0].id);
    } else {
      setLeagueId("");
    }
  }, [leagues]);

  useEffect(() => {
    if (leagueId != "") {
      dispatch(getSeasons(leagueId));
      setSeasonId("");
    }
  }, [leagueId]);

  useEffect(() => {
    if (seasons.length) {
      setSeasonId(seasons[0].id);
    } else {
      setSeasonId("");
    }
  }, [seasons]);

  const enrollSeasonHandler = () => {
    if (teamId == "") {
      alert("team_id is blank! Contact DEV TEAM.");
      return;
    }
    dispatch(enrollSeason(seasonId, teamId));
    setLeagueId("");
    setSeasonId("");
  };

  const deleteFromSeasonHandler = () => {
    if (teamId == "" || seasonId == "") {
      alert("team_id or season_id is blank! Contact DEV TEAM.");
      return;
    }
    dispatch(deleteFromSeason(seasonId, teamId));
    setLeagueId("");
    setSeasonId("");
  };

  const handleChangeTeamName = () => {
    dispatch(changeTeamName(teamId, teamName));
  };

  return (
    <div className="team-detail-wrapper">
      <div className="team-detail-control-box">
        <div className="team-name">
          <h1>{teamDetail?.name}</h1>
          <img className="team-logo" src={teamDetail?.logo} alt="logo" />

          <TextField
            id="location-text-field"
            label="Team Name"
            variant="outlined"
            required={true}
            value={teamName}
            onChange={(event) => {
              setTeamName(event.target.value);
            }}
          />
          <Button
            disabled={teamName.length === 0}
            variant="outlined"
            onClick={handleChangeTeamName}
          >
            CHANGE TEAM NAME
          </Button>
        </div>

        <div className="team-detail-upload-wrapper">
          <h3>Upload Logo</h3>
          <FormControl sx={{ m: 1 }}>
            <InputLabel id="stats-upload-selector-label"></InputLabel>
            <Input
              inputProps={{ accept: "*.*" }}
              multiple={false}
              type="file"
              value={logoFileName}
              onChange={handleFileChange}
            />
          </FormControl>
          {open && (
            <Button
              disabled={logoFileName === ""}
              variant="outlined"
              sx={{ margin: "0.5rem" }}
              onClick={() => {
                if (teamId != "") {
                  dispatch(uploadLogo(teamId, logoFile));
                  handleClose();
                } else {
                  alert("teamId is null. Please raise ticket to Dev team.");
                }
              }}
            >
              upload
            </Button>
          )}
          {!open && (
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={!open}
              onClick={handleToggle}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      </div>
      <div className="team-detail-control-box">
        <div className="team-season-enroll">
          <div className="team-season-enroll-wrapper">
            <h3>Enroll Season</h3>
            <div className="input-wrapper">
              <FormControl sx={{ m: 1, minWidth: 250 }}>
                <InputLabel id="league-selector-label">League</InputLabel>
                <Select
                  labelId="league-selector-label"
                  id="league-selector"
                  value={leagueId}
                  label="League"
                  onChange={(event) => {
                    setLeagueId(event.target.value);
                  }}
                  sx={{ color: "#fff", borderColor: "#fff" }}
                >
                  {leagues.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.abv}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="season-selector-label">Season</InputLabel>
                <Select
                  labelId="season-selector-label"
                  id="season-selector"
                  value={seasonId}
                  label="Season"
                  onChange={(event) => {
                    setSeasonId(event.target.value);
                  }}
                >
                  {seasons.map((item, index) => {
                    return (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {open && (
                <Button
                  disabled={seasonId === ""}
                  variant="outlined"
                  sx={{ margin: "0.5rem" }}
                  onClick={enrollSeasonHandler}
                >
                  enroll
                </Button>
              )}
              {!open && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!open}
                  onClick={handleToggle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              {open && (
                <Button
                  disabled={seasonId === ""}
                  variant="outlined"
                  sx={{ margin: "0.5rem", backgroundColor: "red" }}
                  onClick={deleteFromSeasonHandler}
                >
                  Remove
                </Button>
              )}
              {!open && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!open}
                  onClick={handleToggle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </div>
          </div>
        </div>
      </div>
      <SeasonRosterForm teamId={teamId} />
      <TeamCaptains />
      <TeamCoaches />
    </div>
  );
};

export default TeamDetail;
