import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPosts } from "../store/actions/post";
import { getLeagues } from "../store/actions/matchCreate";
import PostListItem from "../component/PostListItem";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./PostList.scss";

const PostList = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [leagueId, setLeagueId] = useState("Show All");
  const [searchQuery, setSearchQuery] = useState("");
  const { startPage, endPage, isLast, posts } = useSelector(
    (store) => store.postReducer
  );
  const { leagues } = useSelector((store) => store.matchCreateReducer);

  useEffect(() => {
    dispatch(getLeagues());
  }, []);

  useEffect(() => {
    dispatch(getPosts(currentPage, leagueId));
  }, [leagueId]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(getPosts(currentPage, leagueId, searchQuery));
  };

  return (
    <div className="post-list-page-container">
      <div className="post-search-bar-wrapper">
        <FormControl
          sx={{ minWidth: 120 }}
          className="post-search-league-select-form"
        >
          <InputLabel id="post-search-league-select-label">League</InputLabel>
          <Select
            labelId="post-search-league-select-label"
            id="post-search-league"
            value={leagueId}
            onChange={(e) => {
              setLeagueId(e.target.value);
            }}
          >
            <MenuItem value={"Show All"} key={98}>
              Show All
            </MenuItem>
            <MenuItem value={"All"} key={99}>
              All
            </MenuItem>
            {leagues.map((league, index) => (
              <MenuItem value={league.id} key={index}>
                {league.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="post-search-input-form">
          <TextField
            id="search-input"
            placeholder="Search title"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyDown={(e) => {
              e.key === "Enter" ? handleSearchSubmit(e) : null;
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="search-button"
                  onClick={handleSearchSubmit}
                >
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className="post-list-wrapper">
        {!posts.length ? (
          <div>no contents</div>
        ) : (
          posts.map((post) => {
            return <PostListItem key={post.id} post={post} />;
          })
        )}
        <div className="pagination-container">
          {startPage !== 1 && (
            <button
              className="pagination-button"
              onClick={() => {
                dispatch(getPosts(startPage - 1, leagueId));
                setCurrentPage(startPage - 1);
              }}
            >
              {"<"}
            </button>
          )}
          {Array(endPage - startPage + 1)
            .fill()
            .map((_, i) => {
              return (
                <button
                  key={i}
                  className={`pagination-button ${
                    currentPage === startPage + i ? "active" : ""
                  }`}
                  onClick={() => {
                    dispatch(getPosts(startPage + i, leagueId));
                    setCurrentPage(startPage + i);
                  }}
                  disabled={currentPage === startPage + i}
                >
                  {startPage + i}
                </button>
              );
            })}
          {!isLast && (
            <button
              className="pagination-button"
              onClick={() => {
                dispatch(getPosts(endPage + 1, leagueId));
                setCurrentPage(endPage + 1);
              }}
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostList;
