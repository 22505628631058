import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateTimePicker from "@mui/lab/DateTimePicker";
import { useDispatch } from "react-redux";
import { modifyPlayer } from "../store/actions/player";

const PlayerUpdateRow = ({ player }) => {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [quote, setQuote] = useState("");
  // const [birthdate, setBirthdate] = useState(null);

  useEffect(() => {
    setName(player.name);
    setHeight(player.height);
    setWeight(player.weight);
    setQuote(player.quote);
    // setBirthdate(player.birthdate);
  }, [player]);

  return (
    <tr>
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={name || ""} //input error fix
          disabled={disabled}
          onChange={(event) => {
            setName(event.target.value);
          }}
          type="string"
        />
      </th>
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={weight || ""}
          disabled={disabled}
          onChange={(event) => {
            setWeight(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={height || ""}
          disabled={disabled}
          onChange={(event) => {
            setHeight(event.target.value);
          }}
        />
      </th>
      <th>
        <TextField
          id="player-detail-text-field"
          variant="outlined"
          sx={{ margin: "0.2rem" }}
          value={quote || ""}
          disabled={disabled}
          onChange={(event) => {
            setQuote(event.target.value);
          }}
        />
      </th>
      {/* <th>
        <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ m: 1 }}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField id="player-detail-text-field" {...props} />
            )}
            label="Date&Time"
            value={birthdate}
            format="yyyy-MM-dd"
            onChange={(event) => {
              setBirthdate(event.target.value);
            }}
          />
        </LocalizationProvider>
      </th> */}

      <th>
        <Button
          variant="outlined"
          onClick={() => {
            setDisabled(!disabled);

            if (
              name != player.name ||
              weight != player.weight ||
              height != player.height ||
              quote != player.quote
            ) {
              dispatch(modifyPlayer(player, name, weight, height, quote));
            }
          }}
        >
          {disabled ? "modify" : "update"}
        </Button>
      </th>
    </tr>
  );
};

export default PlayerUpdateRow;
