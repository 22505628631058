import { GET_LEAGUES, GET_SEASONS, GET_TEAMS } from "../actions/matchCreate";
const initialState = {
  leagues: [],
  seasons: [],
  teams: [],
};

export default function matchCreateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEAGUES:
      return {
        ...state,
        leagues: action.data,
      };
    case GET_SEASONS:
      return {
        ...state,
        seasons: action.data,
      };
    case GET_TEAMS:
      return {
        ...state,
        teams: action.data,
      };
    default:
      return state;
  }
}
