import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  getTeamCoaches,
  addTeamCoaches,
  deleteTeamCoach,
} from "../store/actions/teamDetail";
import { getUserByUsername } from "../store/actions/user";
import "./TeamCoaches.scss";

const TeamCoaches = () => {
  const dispatch = useDispatch();
  const { teamDetail, teamCoaches } = useSelector(
    (store) => store.teamDetailReducer
  );
  const { users } = useSelector((store) => store.userReducer);
  const [newCoaches, setNewCoaches] = useState([]);
  const prevController = useRef();

  useEffect(() => {
    dispatch(getTeamCoaches(teamDetail?.id));
  }, [teamDetail]);

  const handleRemoveCoach = (e) => {
    dispatch(deleteTeamCoach(e.target.value));
  };

  const handleSearchSubmit = (event, value) => {
    if (prevController.current) {
      prevController.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;
    prevController.current = controller;

    console.log(event);

    dispatch(getUserByUsername(value, signal));
  };

  const handleAutoCompleteChange = (event, value) => {
    console.log(event);
    setNewCoaches([...value.map((user) => user.id)]);
  };

  const confirmAddCoaches = () => {
    dispatch(addTeamCoaches(teamDetail?.id, newCoaches));
  };

  return (
    <div className="team-detail-coaches-container">
      <h3>{teamDetail?.name} Coaches</h3>
      <div className="team-detail-captains">
        <div className="team-captains-list">
          <div className="team-coaches-list-title">
            <h4>Username</h4>
          </div>
          {teamCoaches?.map((coach) => {
            return (
              <div className="team-coach-item" key={coach.id}>
                <span>{coach.username}</span>
                <div className="remove-button-wrapper">
                  <Button
                    variant="outlined"
                    color="error"
                    value={coach.id}
                    onClick={handleRemoveCoach}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="team-captains-add">
          <Autocomplete
            multiple
            id="user-search"
            options={users}
            onInputChange={handleSearchSubmit}
            getOptionLabel={(option) => option.username}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Search User" />
            )}
            onChange={handleAutoCompleteChange}
          />
          <Button
            disabled={newCoaches.length === 0}
            variant="outlined"
            onClick={confirmAddCoaches}
          >
            Add Coaches
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TeamCoaches;
