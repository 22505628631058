export const leagueCategory = [
  "Sketch",
  "Youth",
  "Corporate",
  "Korean",
  "Training",
  "Others",
  "Asian",
  "Alumni",
  "Sketch-Korea",
  "BLeague",
  "Premium",
];
