import React from "react";
import "./TeamListItem.scss";
import { useNavigate } from "react-router-dom";

const TeamListItem = ({ team }) => {
  const navigate = useNavigate();

  return (
    <div
      className="team-item"
      onClick={() => {
        navigate(`${team.id}`);
      }}
    >
      <div className="team-name">
        <span>{team.name}</span>
      </div>
    </div>
  );
};
export default TeamListItem;
