import {
  GET_MATCHES,
  GET_MATCHES_SUCCESS,
  GET_MATCHES_FAIL,
} from "../actions/match";
const initialState = {
  matches: [],
  hasPrev: true,
  hasNext: true,
  matchesLoading: false,
};

export default function matchReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MATCHES:
      return {
        ...state,
        matchesLoading: true,
      };
    case GET_MATCHES_SUCCESS:
      return {
        ...state,
        // matches: [...state.matches, ...action.data],
        matches: action.data,
        hasPrev: action.hasPrev,
        hasNext: action.hasNext,
        matchesLoading: false,
      };
    case GET_MATCHES_FAIL:
      return {
        ...state,
        matchesLoading: false,
      };
    default:
      return state;
  }
}
