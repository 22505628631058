import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import YoutubeEmbed from "../component/YoutubeEmbed";
import {
  Button,
  TextField,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ShootingChartForm from "../component/ShootingChartForm";
import { updateMatchVideo, uploadMvp } from "../store/actions/matchDetail";
import "./MatchDetailContent.scss";
import { uploadShootingChart } from "../store/actions/matchDetail";

const MatchDetailContent = ({ homePlayers, awayPlayers }) => {
  const [videoId, setVideoId] = useState("");
  const [videoIdDisabled, setVideoIdDisabled] = useState(true);
  const [mvpImageName, setMvpImageName] = useState("");
  const [mvpImage, setMvpImage] = useState("");
  const [mvpId, setMvpId] = useState("");
  const [device, setDevice] = useState("10.9");
  const dispatch = useDispatch();
  const { matchId } = useParams();
  const { matchDetail, winPlayers } = useSelector(
    (store) => store.matchDetailReducer
  );
  const [shootingCharts, setShootingCharts] = useState([]);

  useEffect(() => {
    setVideoId(matchDetail?.match_videos ?? "");
  }, [matchDetail]);

  const handleImageChange = (event) => {
    setMvpImageName(event.target.value);
    setMvpImage(event.target.files ? event.target.files[0] : null);
  };

  const handleShootingCharts = (matchStatId, shootingChart) => {
    const duplicateData = shootingCharts.find((shootingChartData) =>
      shootingChartData.includes(matchStatId)
    );
    duplicateData
      ? (duplicateData[1] = shootingChart)
      : setShootingCharts([...shootingCharts, [matchStatId, shootingChart]]);

    shootingChart ??
      setShootingCharts(
        shootingCharts.filter(
          (shootingChartData) => shootingChartData[0] !== matchStatId
        )
      );
  };

  const disableButton = (event) => {
    event.target.classList.toggle("Mui-disabled");
    document.body.style.cursor = "wait";
  };

  const handleDeviceChange = (event, size) => {
    setDevice(size);
  };

  return (
    <div className="match-detail-content-wrapper">
      <div className="match-detail-video-wrapper">
        <h1>Match Video</h1>
        <div className="match-detail-video-form">
          <TextField
            id="match-detail-video-text-field"
            label="Video"
            variant="outlined"
            sx={{ margin: "0.5rem" }}
            value={videoId}
            disabled={videoIdDisabled}
            onChange={(event) => {
              setVideoId(event.target.value);
            }}
          />
          <Button
            variant="outlined"
            sx={{ margin: "0.5rem" }}
            onClick={() => {
              if (!videoIdDisabled) {
                dispatch(updateMatchVideo(matchId, videoId));
              }
              setVideoIdDisabled(!videoIdDisabled);
            }}
          >
            {videoIdDisabled ? "modify" : "update"}
          </Button>
        </div>

        <div className="match-detail-youtube">
          {matchDetail?.match_videos ? (
            <YoutubeEmbed embedId={matchDetail.match_videos} />
          ) : (
            <div>no video available</div>
          )}
        </div>
      </div>

      {winPlayers.length ? (
        <div className="match-detail-mvp-wrapper">
          <h1>MVP</h1>
          <div className="match-detail-mvp-input-wrapper">
            <FormControl sx={{ m: 1 }}>
              <InputLabel id="mvp-selector-label">MVP</InputLabel>
              <Select
                labelId="mvp-selector-label"
                id="mvp-selector"
                value={mvpId}
                label="League"
                onChange={(event) => {
                  setMvpId(event.target.value);
                }}
              >
                {winPlayers.slice(0, -1).map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className="match-detail-mvp-file-wrapper">
              <Input
                inputProps={{ accept: "image/*" }}
                multiple={false}
                type="file"
                value={mvpImageName}
                onChange={handleImageChange}
              />
              <Button
                disabled={mvpImageName === ""}
                variant="outlined"
                sx={{ margin: "0.5rem" }}
                onClick={() => {
                  if (mvpId != "") {
                    dispatch(uploadMvp(mvpId, mvpImage));
                  } else {
                    alert("Please choose MVP first!");
                  }
                }}
              >
                upload
              </Button>
            </div>
            {matchDetail?.mvp ? (
              <div className="match-detail-mvp-image-wrapper">
                <h3>MVP: {matchDetail.mvp.name}</h3>
                <img src={matchDetail.mvp.mvp_photo} alt="logo" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      <div className="match-detail-shooting-chart-wrapper">
        <h1>shooting chart</h1>
        <div className="device-button-wrapper">
          <span>Select the size of your device!</span>
          <ToggleButtonGroup
            value={device}
            onChange={handleDeviceChange}
            size="large"
            color="primary"
            exclusive
          >
            <ToggleButton value="10.5">10.5</ToggleButton>
            <ToggleButton value="10.9">10.9</ToggleButton>
            <ToggleButton value="11.9">11.9</ToggleButton>
          </ToggleButtonGroup>
          <span>
            Selected Device Size :{" "}
            <span style={{ fontSize: "2rem" }}>{device}</span> inches
          </span>
        </div>
        <h3>Home: {matchDetail?.home_team.name}</h3>
        <div className="match-detail-home-shooting-chart">
          {homePlayers?.map((item, index) => {
            if (
              item.name !== "Totals" &&
              item.name !== "ETC" &&
              item.is_finished
            )
              return (
                <ShootingChartForm
                  matchStat={item}
                  key={index}
                  onChange={handleShootingCharts}
                  index={index}
                  deviceSize={device}
                  side="home"
                />
              );
          })}
        </div>
        <h3>Away: {matchDetail?.away_team.name}</h3>
        <div className="match-detail-away-shooting-chart">
          {awayPlayers?.map((item, index) => {
            if (
              item.name !== "Totals" &&
              item.name !== "ETC" &&
              item.is_finished
            )
              return (
                <ShootingChartForm
                  matchStat={item}
                  key={index}
                  onChange={handleShootingCharts}
                  index={index}
                  deviceSize={device}
                  side="away"
                />
              );
          })}
        </div>
      </div>
      <Button
        disabled={!shootingCharts.length}
        variant="outlined"
        sx={{ margin: "0.5rem" }}
        onClick={(event) => {
          dispatch(uploadShootingChart(shootingCharts));
          disableButton(event);
        }}
      >
        upload
      </Button>
    </div>
  );
};

export default MatchDetailContent;
